import helpers from './helpers'

/* global Routing */

$(document).ready(function () {
  // Delete enfant
  $('body').on('click', '.remove-children, .remove-adulte', function () {
    const $ico = $(this)
    const pId = $ico.data('key')

    let nomRoute = null
    let message = ''
    if ($ico.hasClass('remove-children')) {
      nomRoute = 'st_famille_remove_enfant'
      message = 'Confirmez-vous le retrait de cet enfant de votre dossier ?'
    } else if ($ico.hasClass('remove-adulte')) {
      nomRoute = 'st_famille_remove_adulte'
      message = 'Confirmez-vous le retrait de cet adulte de votre dossier ?'
    }

    const onValidation = function () {
      const route = Routing.generate(nomRoute, {
        st_slug: $('body').data('structure'),
        personne: pId
      })

      $.get({
        url: route,
        success: function (response) {
          if (response.state === true) {
            $('#card-p-' + pId).parent().slideUp('slow')
          }
        }
      })
    }

    helpers.confirm({
      title: 'Confirmation requise !',
      content: message
    }, onValidation)
  })
})
