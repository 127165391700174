/* eslint-disable no-prototype-builtins */
/* global alert FormData Routing */
import _ from 'lodash'
import helpers from './helpers'

let modalOuverte = false
let currentPlanningPHP = null
let personnes = null
let activites = null
let nav = null
let multiple = true

const bouton = $('#navPrecedent')
if (bouton.closest('div').hasClass('planning-nav')) {
  personnes = bouton.closest('div.planning-nav').data('personnes')
  activites = bouton.closest('div.planning-nav').data('activites')
  nav = bouton.closest('div.planning-nav')
} else if (bouton.closest('div').hasClass('calendar-nav')) {
  personnes = bouton.closest('div.calendar-nav').data('personnes')
  activites = bouton.closest('div.calendar-nav').data('activites')
  nav = bouton.closest('div.calendar-nav')
}

function getNombreElementsDansPanier () {
  let nb = 0
  if ($('.panier-container .panier-nb').html()) {
    nb = parseInt($('.panier-container .panier-nb').html())
  }

  return nb
}

function afficheMasqueBoutonPanier (nb) {
  if (nb === 0) {
    if (!$('#showPanier').hasClass('d-none')) {
      $('#showPanier').addClass('d-none')
    }
  } else if (nb > 0) {
    if ($('#showPanier').hasClass('d-none')) {
      $('#showPanier').removeClass('d-none')
    }
  }
}

// * Une fois quel a page est chargée
$(window).on('load', function () {
  let nb = getNombreElementsDansPanier()
  afficheMasqueBoutonPanier(nb)
  // * Cela permet de surveiller les modifications du DOM et ici plus précisement la modification du nombre d'éléments dans la pastille du panier
  $('.panier-container .panier-nb').on('DOMSubtreeModified', function () {
    nb = getNombreElementsDansPanier()
    afficheMasqueBoutonPanier(nb)
  })
})

// * Petit tricks pour afficher les 2 modales à la suite dans le cas du blocage facture non bloquant.
// * On cache la planning-modal puis on l'affiche une fois la modal facture fermée
$('#modal').on('hidden.bs.modal', function () {
  $('#planning-modal').css('display', 'block')
  modalOuverte = false
})

// * Si la modale est fermée de quelconque manière que ce soit alors on doit passer le flag à faux
$('#planning-modal').on('hidden.bs.modal', function () {
  modalOuverte = false
})

$('body').on('click', '#annuler', function () {
  $('#planning-modal .modal').modal('hide')
})

$('body').on('click', 'td[name="celluleClicable"]', function (e) {
  if (modalOuverte === true) {
    return
  }
  modalOuverte = true
  currentPlanningPHP = $(this).closest('#planningsPHP')
  const slug = $('body').data('structure')
  const inscription = $(this).data('id')
  const dateClic = $(this).data('date')
  const personne = $(this).data('personne')
  const activite = $(this).data('activite')

  $.post({
    url: Routing.generate('planning_clic', { st_slug: slug }),
    data: { inscription: inscription, dateClic: dateClic, personne: personne, activite: activite },
    success: function (reponse) {
      if (reponse.valide) {
        const personne = reponse.values.personne
        const element = reponse.values.type
        const key = reponse.values.key
        const date = reponse.values.date
        const isAbsent = reponse.values.absent

        if (currentPlanningPHP.data('personnes') && currentPlanningPHP.data('activites')) {
          personnes = currentPlanningPHP.data('personnes')
          activites = currentPlanningPHP.data('activites')
          multiple = false
        }
        $.get({
          url: Routing.generate('planning_element', { st_slug: slug, personne: personne, element: element, key: key, date: date, isAbsent: isAbsent }),
          success: function (reponse2) {
            if (_.isString(reponse2)) {
              const urlIsfacture = Routing.generate('reservation_isfacture', {
                st_slug: slug
              })

              $.get(urlIsfacture).done(function (data) {
                if (data.blocage === true) {
                  $('#modalLabel').html('Attention, facture en attente')

                  let texte = '<div id="div1">'
                  // * On laisse le texte par défaut si la valeur du champ en base est null
                  if (data.texte === null) {
                    texte += 'Vous avez une ou des facture(s) en attente(s), vous ne pouvez pas vous inscrire'
                  } else {
                    texte += data.texte
                  }
                  texte += '</div>'
                  $('#modalbody').html(texte)

                  $('#modal').modal('show')
                  modalOuverte = false
                }
                if (data.ignoreBlocage || !data.blocage) {
                  $('#planning-modal').data('personne', personne)
                  $('#planning-modal').data('key', key)
                  $('#planning-modal').data('type', element)
                  $('#planning-modal').data('date', date)
                  $('#planning-modal').data('absent', isAbsent)
                  $('#planning-modal').html(reponse2)
                  if (data.blocage) {
                    $('#planning-modal').css('display', 'none')
                  }
                  // * On affiche la modal
                  $('#planning-modal').find('.modal:first').modal({})
                  helpers.initialiserTimepicker()
                  helpers.initialiserToggle()
                }
              })
            } else {
              if (reponse2.hasOwnProperty('success')) {
                helpers.displayNotification('Action réalisée', reponse2.success, {
                  headerClass: 'bg-success',
                  delay: 1000
                })
                $.post({
                  url: Routing.generate('planning_js', { st_slug: $('body').data('structure') }),
                  data: { personnes: personnes, activites: activites, date: nav.data('mois'), type: null, ajax: true, multiple: multiple },
                  beforeSend: function () {
                    $('#mainContent div.d-flex').css('opacity', 0.2)
                    $('#loadingMessage').removeClass('d-none').addClass('d-flex')
                  },
                  success: function (reponse3) {
                    nav.data('mois', reponse3.date)
                    currentPlanningPHP.replaceWith(reponse3.html)
                    $('#moisAnneeCalendrier').html(reponse3.moisAnnee)
                    helpers.loadPanier($('.panier-container .panier-nb'))
                  },
                  complete: function () {
                    $('#loadingMessage').addClass('d-none').removeClass('d-flex')
                    $('#mainContent div.d-flex').css('opacity', 1)
                  }
                })
                modalOuverte = false
              }
              if (reponse2.hasOwnProperty('errors')) {
                if (Array.isArray(reponse2.errors)) {
                  helpers.displayNotification('Information(s)', reponse2.errors.join('<br>'), {
                    headerClass: 'bg-danger',
                    delay: 6000
                  })
                } else {
                  helpers.displayNotification('information(s)', reponse2.errors, {
                    headerClass: 'bg-danger',
                    delay: 6000
                  })
                }
                modalOuverte = false
              }
            }
          }
        })
      } else {
        $('#modalLabel').html('Commande en cours')

        $('#modalbody').html('<div id="div1">Vous avez une commande en cours contenant des adhésions.<br>Une fois celle-ci validée par la structure vous pourrez de nouveau vous inscrire.</div>')

        $('#modal').modal('show')
        modalOuverte = false
      }
    }
  })
})

$('body').on('click', '#navPrecedent, #navSuivant', function (e) {
  const bouton = $(this)
  let type = null
  if (bouton.attr('id') === 'navPrecedent') {
    type = 'precedent'
  } else if (bouton.attr('id') === 'navSuivant') {
    type = 'suivant'
  }
  if (bouton.closest('div').hasClass('planning-nav')) {
    $.post({
      url: Routing.generate('planning_js', { st_slug: $('body').data('structure') }),
      data: { personnes: personnes, activites: activites, date: nav.data('mois'), type: type, ajax: true },
      beforeSend: function () {
        $('#mainContent div.d-flex').css('opacity', 0.2)
        $('#loadingMessage').removeClass('d-none').addClass('d-flex')
      },
      success: function (reponse) {
        nav.data('mois', reponse.date)
        $('#planningsPHP').replaceWith(reponse.html)
        $('#moisAnneeCalendrier').html(reponse.moisAnnee)
      },
      complete: function () {
        $('#loadingMessage').addClass('d-none').removeClass('d-flex')
        $('#mainContent div.d-flex').css('opacity', 1)
      }
    })
  } else if (bouton.closest('div').hasClass('calendar-nav')) {
    $.post({
      url: Routing.generate('st_activite_calendar', { st_slug: $('body').data('structure') }),
      data: { ajax: true, date: bouton.closest('div.calendar-nav').data('mois'), type: type },
      beforeSend: function () {
        $('#mainContent div.d-flex').css('opacity', 0.2)
        $('#loadingMessage').removeClass('d-none').addClass('d-flex')
      },
      success: function (reponse) {
        $('div.calendar-nav').data('mois', reponse.dateMois)
        $('#planning-container #planningsPHP').not(':first').remove()
        if (reponse.html === '') {
          $('#planning-container #planningsPHP:first').html('<center class="mt-3"><h2>Aucune inscription pour cette période</h2></center>')
        } else {
          $('#planningsPHP').replaceWith(reponse.html)
        }
        $('#moisAnneeCalendrier').html(reponse.libelleMoisAnnee)
      },
      complete: function () {
        $('#loadingMessage').addClass('d-none').removeClass('d-flex')
        $('#mainContent div.d-flex').css('opacity', 1)
      }
    })
  }
})

$('body').on('click', '#btnMaj, #inscrire', function () {
  const container = $('#planning-modal')
  const form = container.find('form')
  const formData = new FormData(form[0])
  const slug = $('body').data('structure')
  let absent = 'f'
  if (container.data('absent')) {
    absent = container.data('absent')
  }
  let personne = null
  if (container.data('personne').id) {
    personne = container.data('personne').id
  } else {
    personne = container.data('personne')
  }

  const url = Routing.generate('planning_element', {
    st_slug: slug,
    personne: personne,
    element: container.data('type'),
    key: container.data('key'),
    date: container.data('date'),
    isAbsent: absent
  })

  $.post({
    url: url,
    data: formData,
    processData: false,
    contentType: false,
    success: function (data) {
      notification(data)
      helpers.loadPanier($('.panier-container .panier-nb'))
      if (data.hasOwnProperty('success')) {
        $('#planning-modal .modal').modal('hide')
        $.post({
          url: Routing.generate('planning_js', { st_slug: $('body').data('structure') }),
          data: { personnes: personnes, activites: activites, date: nav.data('mois'), type: null, ajax: true, multiple: multiple },
          beforeSend: function () {
            $('#mainContent div.d-flex').css('opacity', 0.2)
            $('#loadingMessage').removeClass('d-none').addClass('d-flex')
          },
          success: function (reponse3) {
            nav.data('mois', reponse3.date)
            currentPlanningPHP.replaceWith(reponse3.html)
            $('#moisAnneeCalendrier').html(reponse3.moisAnnee)
          },
          complete: function () {
            $('#loadingMessage').addClass('d-none').removeClass('d-flex')
            $('#mainContent div.d-flex').css('opacity', 1)
          }
        })
      }
    },
    error: function (e) {
      alert('Une erreur est survenue lors de la modification.')
    }
  })
  modalOuverte = false
})

$('body').on('click', '#btnDelete', function () {
  const container = $('#planning-modal')
  const slug = $('body').data('structure')
  const url = Routing.generate('planning_absence', {
    st_slug: slug,
    id: container.data('key'),
    type: container.data('type')
  })

  $.get({
    url: url,
    success: function (data) {
      notification(data)
      if (data.hasOwnProperty('success')) {
        $('#planning-modal .modal').modal('hide')
        $.post({
          url: Routing.generate('planning_js', { st_slug: $('body').data('structure') }),
          data: { personnes: personnes, activites: activites, date: nav.data('mois'), type: null, ajax: true, multiple: multiple },
          beforeSend: function () {
            $('#mainContent div.d-flex').css('opacity', 0.2)
            $('#loadingMessage').removeClass('d-none').addClass('d-flex')
          },
          success: function (reponse3) {
            nav.data('mois', reponse3.date)
            currentPlanningPHP.replaceWith(reponse3.html)
            $('#moisAnneeCalendrier').html(reponse3.moisAnnee)
          },
          complete: function () {
            $('#loadingMessage').addClass('d-none').removeClass('d-flex')
            $('#mainContent div.d-flex').css('opacity', 1)
          }
        })
      }
    },
    error: function (data) {
      alert('Une erreur est survenue lors de l\'annulation.')
    }
  })
  modalOuverte = false
})

function notification (data) {
  helpers.loadPanier($('.panier-container .panier-nb'))
  if (data.hasOwnProperty('success')) {
    helpers.displayNotification('Action réalisée', data.success, {
      headerClass: 'bg-success',
      delay: 1000
    })
  }
  if (data.hasOwnProperty('errors')) {
    if (Array.isArray(data.errors)) {
      helpers.displayNotification('Information(s)', data.errors.join('<br>'), {
        headerClass: 'bg-danger',
        delay: 6000
      })
    } else {
      helpers.displayNotification('information(s)', data.errors, {
        headerClass: 'bg-danger',
        delay: 6000
      })
    }
  }
}
