// jQuery
import $ from 'jquery'

// Bootstrap
import 'bootstrap'

// Font awesome 4.7.0
import 'font-awesome/css/font-awesome.css'

// momentjs
import moment from 'moment'

// Global styles
import '../scss/app.scss'

// Used by WOW.js
import 'animate.css'

// wow.js
import './wow.js'

// select2
import 'select2/dist/js/select2.full'
import 'select2/dist/js/i18n/fr.js'
import 'select2/dist/css/select2.min.css'

// velocity.js
import 'velocity-animate'

// jonthornton/jquery-timepicker
import 'timepicker'

// bootstrap toggle
import './bootstrap-toggle.min.js'
import '../scss/bootstrap-toggle.min.css'

// Custom
import './routing.js'
import './schedule.js'
import './kidz.js'
import './revolution.js'
import './main.js'
import './fullcalendar.js'
import './form.js'
import './enfant.js'
import './reservation.js'
import './contact.js'

global.$ = global.jQuery = $
moment.locale('fr')
