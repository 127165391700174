import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listWeekPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'
import moment from 'moment'
import _ from 'lodash'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import Planning from './schedule'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/list/main.css'
import '@fullcalendar/bootstrap/main.css'

/* global Routing */

$(document).ready(function () {
  const container = $('#planning-container')
  // *Gestion affichage mobile
  let ww = window.innerWidth
  if (container.length < 1 || $('#planning-container').hasClass('no-responsive')) {
    return
  }
  $('#showCalendar').on('click', function () {
    container.html('')
    initCalendar()
    $('#bloc-participants').removeClass('hide')
    $('#bloc-legende').addClass('hide')
  })
  $('#showPlanning').on('click', function () {
    $('#bloc-participants').addClass('hide')
    container.html('')
    container.removeClass()
    $('#bloc-legende').removeClass('hide')

    const url = Routing.generate('st_activite_calendar', {
      st_slug: $('body').data('structure')
    })

    const startOfPreviousMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')

    $.post({
      url: url,
      data: { ajax: true, date: startOfPreviousMonth, type: 'suivant' },
      success: function (reponse) {
        const html =
          '<div class="col-12 planning-tables">' +
            '<div class="calendar-nav" data-mois="' + reponse.dateMois + '" data-personnes="" data-activites="">' +
              '<span class="btn-group mr-2">' +
                '<button id="navPrecedent" class="btn btn-primary"><i class="fa fa-chevron-left"></i></button>' +
                '<button id="navSuivant"class="btn btn-primary"><i class="fa fa-chevron-right"></i></button>' +
              '</span>' +
              '<h2 id="moisAnneeCalendrier" class="font-size-sm-10">' + reponse.libelleMoisAnnee + '</h2>' +
              '<span class="planning-loading" style="display: none;">' +
                '<div class="spinner-border text-inscription" role="status">' +
                  '<span class="sr-only">Loading...</span>' +
                '</div>' +
              '</span>' +
            '</div>' +
            reponse.html +
          '</div>'
        $('#planning-container').html(html)
      }
    })
  })
  $('#bloc-participants input').on('change', function () {
    container.html('')
    if (ww <= 600) {
      initMobileView(container)
    } else {
      initCalendar()
    }
  })

  $(window).resize(function () {
    ww = window.innerWidth
    if (ww <= 600) {
      initMobileView(container)
    }
  })

  if (ww <= 600) {
    initMobileView(container)
  }

  $('#formPrintCalendar').on('submit', function (e) {
    e.preventDefault()
    const form = document.getElementById('formPrintCalendar')
    // eslint-disable-next-line no-undef
    const formData = new FormData(form)
    const route = Routing.generate('st_activite_generer_calendrier', {
      st_slug: $('#body').data('structure')
    })

    $.post({
      url: route,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        if ($('.modal-footer a.btn-dl-pdf').length > 0) {
          $('.modal-footer .btn-dl-pdf').remove()
        }
        const button = `
          <a href="` + response.link + `" target="_blank" rel="noreferrer" class="btn btn-info btn-sm btn-dl-pdf">Télécharger</a>
        `
        $('.modal-footer').append(button)
      }
    })
  })
})

function initMobileView ($container) {
  $container.html('')
  $('#bloc-legende, #showPlanning, #showCalendar').addClass('hide')
  $('#bloc-participants').removeClass('hide')
  initCalendar('listWeek', 'listWeek')
}

function getParticipants () {
  const participants = []

  $('#bloc-participants input').each(function (i) {
    if ($(this).is(':checked')) {
      participants.push($(this).val())
    }
  })

  return participants
}

// eslint-disable-next-line no-unused-vars
function initCalendar (view = 'timeGridWeek', right = 'timeGridWeek,listWeek') {
  const calendarEl = document.getElementById('planning-container')

  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin, timeGridPlugin, listWeekPlugin, bootstrapPlugin],
    themeSystem: 'bootstrap',
    locale: frLocale,
    defaultView: view,
    height: 600,
    header: {
      left: 'prev,next',
      center: 'title',
      right: right
    },
    eventLimit: true, // allow "more" link when too many events
    allDaySlot: false,
    events: function (info, onSuccess, onError) {
      const route = Routing.generate('planning_affichage', {
        st_slug: $('body').data('structure'),
        start: moment(info.start).format('YYYY-MM-DD'),
        end: moment(info.end).format('YYYY-MM-DD'),
        participants: getParticipants()
      })
      $.get({
        url: route,
        success: function (data) {
          var events = []
          data.forEach(function (r) {
            const t = r.inscriptions.map(function (inscription) {
              const activite = _.find(r.activites, { id: inscription.activite })
              return {
                title: activite.nom,
                start: new Date(inscription.date + 'T' + inscription.heure_arrivee),
                end: new Date(inscription.date + 'T' + inscription.heure_depart),
                backgroundColor: activite.couleur,
                borderColor: activite.couleur,
                extendedProps: {
                  inscription: inscription,
                  personne: r.personne
                }
              }
            })
            events = events.concat(t)
          })
          onSuccess(events)
        },
        error: function (error) {
          onError(error)
        }
      })
    },
    eventRender: function (info) {
      if (info.view.type === 'dayGridMonth') return

      let fcTitle = $(info.el).find('.fc-title')
      if (info.view.type === 'listWeek') {
        fcTitle = $(info.el).find('.fc-list-item-title')
      }
      if (info.event.extendedProps.inscription.avec_repas) {
        fcTitle.prepend('<i class="fa fa-cutlery"></i> ')
      }

      if (info.event.extendedProps.inscription.enAttente) {
        fcTitle.prepend('<span class="badge badge-info">Sur liste d\'attente</span>&nbsp;<br/>')
      }

      if (info.event.extendedProps.inscription.abscence) {
        fcTitle.prepend('<span class="badge badge-info">Absent</span>&nbsp;<br/>')
      }

      fcTitle.append('<div class="font-weight-bold">' + info.event.extendedProps.personne.nom + '</div>')
    },
    loading: function (isLoading) {
      const spinner = $('<div class="spinner-border text-inscription" role="status"><span class="sr-only">Loading...</span></div>')
      if (isLoading) {
        spinner.appendTo('#planning-container .fc-center')
        return
      }

      $('#planning-container').find('.spinner-border').remove()
    }
  })
  calendar.render()
}
