/* eslint-disable no-undef */
/* eslint-disable no-multi-str */
import moment from 'moment'
import Cookies from 'js-cookie'
import Planning from './schedule'
import helpers from './helpers'
import tools from './tools'
import { find } from 'lodash'

/* global FileReader Routing alert */
$(document).ready(function () {
  // Bandeau
  if (getCookie('eu-consent') === false) {
    tools.cookieHeadband()
  }

  $('body').on('click', '.gdpr-config', function (e) {
    e.stopImmediatePropagation()
    tools.closeCookieHeadband()
    tools.cookiePrefsInit()
  })
  $('body').on('click', '.gdpr-headband-button.accept-all', function () {
    tools.acceptAllCookies()
  })
  $('body').on('click', '.gdpr-headband-button.cancel-all', function () {
    tools.cancelAllCookies()
  })
  $('body').on('click', '.gdpr-close', function (e) {
    e.stopImmediatePropagation()
    tools.closeCookiePanel()
  })

  // Rev Slider
  if ($('.rev_slider_wrapper').length > 0) {
    $('.setRevColor').each(function (n, elm) {
      const bg = $(elm).data('bg')
      $(elm).css('background-color', bg)
    })
  }

  // Tooltip
  $('[data-toggle="tooltip"]').tooltip()

  $('body .btn-url.active').each(function (i, elm) {
    const $btn = $(elm)

    let $container = $('#view-container')

    if ($btn.data('container') !== undefined) {
      $container = $('#' + $(elm).data('container'))
    }

    $btn.parent().children().removeClass('active')
    $btn.addClass('active')

    let inModal = false
    if ($btn.hasClass('in-modal')) {
      $('.modal-wrapper-main').show('slow')
      inModal = true
    }

    helpers.loadContent($container, $(elm).data('url'), inModal)
  })

  $('body').on('click', '.btn-url', function (e) {
    e.stopImmediatePropagation()

    const $btn = $(this)

    let $container = $('#view-container')

    if ($btn.data('container') !== undefined) {
      $container = $('#' + $(this).data('container'))
    }

    $btn.parent().children().removeClass('active')
    $btn.addClass('active')

    let inModal = false
    if ($btn.hasClass('in-modal')) {
      $('.modal-wrapper-main').show('slow')
      inModal = true
    }

    helpers.loadContent($container, $(this).data('url'), inModal)
  })

  $('body').on('click', '.btn-load-modal', function (e) {
    e.preventDefault()
    const $btn = $(this)
    const url = $btn.data('url')

    if ($btn.hasClass('redirect')) {
      window.location = url
    }

    helpers.loadModal(url)
  })

  $('body').on('click', '.delete-alert', function () {
    const $alerte = $(this)
    $.get({
      url: $alerte.data('url'),
      success: function () {
        $alerte.hide('slow')
      }
    })
  })

  $('body').on('click', '.close-modal', function () {
    const $btn = $(this)
    const modalId = $btn.data('modal')

    if (modalId === undefined) {
      return
    }
    $('body').removeClass('modal-open')
    $('body').css('padding-right', '0px')
    $('body #' + modalId).fadeOut('slow', function () {
      $(this).remove()
    })
    $('.modal-backdrop').hide('slow', function () {
      $(this).remove()
    })
  })

  // Affichage aperçu image sélectionnée dans le formulaire d'upload
  $('body').on('change', '.custom-file-input', function () {
    const $input = $(this)
    const fileName = $input.val().split('\\').pop()
    $input.siblings('.custom-file-label').html(fileName)

    const files = this.files
    if (files === undefined || files.length === 0) return

    // Retour si ce n'est pas une image
    const fileType = files[0]['type']
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    if (!validImageTypes.includes(fileType)) {
      return
    }

    // Afficher un aperçu de l'image sélectionnée
    const reader = new FileReader()
    reader.onload = function (e) {
      let $previewEl = ''
      let $parent = ''
      if ($('#enfantForm').length > 0) {
        $parent = $('body #renderedImage')
        const inputTarget = $parent.data('input-target')
        $('#' + inputTarget).val(e.target.result)
        $previewEl = $parent.children('.preview-photo')
      } else {
        $parent = $input.parent().parent()
        $parent.children('a').remove() // Première fois pour retirer le lien de téléchargement

        $previewEl = $parent.children('.preview-photo')
      }

      if ($previewEl.length === 0) {
        $parent.append('<img class="preview-photo" src="' + e.target.result + '" alt="prévisualisation de l\'image" style="max-width:150px">')
      } else {
        $previewEl.attr('src', e.target.result)
      }
    }
    reader.readAsDataURL(files[0])
  })

  $('body').on('keyup', '.tel-masque', function () {
    const $elm = $(this)
    let value = $elm.val()

    if (value.length < 2) {
      return
    }
    value = value.replace(/\D+\+/, '').slice(0, 18).replace(/(\d{2})(?=\d)/g, '$1.')

    $elm.val(value)
  })

  const $loader = $('#loader')
  const $bar = $('#loader-bar')

  $('.upPer').on('click', function (e) {
    e.preventDefault()
    const target = $(this).data('target')
    $('#' + target).click()
  })

  // DFast upload document alerte
  $('body').on('change', 'input.fast-upload', function () {
    $('.uploadError').html('')
    const $elm = $(this)
    const files = this.files
    let id = $(this).attr('id')
    id = id.split('-')[1]
    if (files === undefined || files.length === 0) return

    const fileSize = files[0]['size']
    const fileType = files[0]['type']
    const validTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf']
    if (!validTypes.includes(fileType)) {
      $('.uploadError').html('<i class="fa fa-exclamation-circle text-danger"></i> L\'extension du fichier n\'est pas valide. Extension autorisée : gif, jpeg, png et pdf.')
      return
    }
    let maxSize = 10
    if (fileType === 'application/pdf') {
      maxSize = 3
    }
    if ((fileSize / 1024 / 1024) > maxSize) {
      $('.uploadError').html('<i class="fa fa-exclamation-circle text-danger"></i> La taille du fichier est trop volumineuse, taille max acceptée : ' + maxSize + 'Mo')
      return
    }
    $loader.show('slow', function () {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onloadstart = function (e) {
      }
      reader.onloadend = function (e) {
        const route = Routing.generate('st_alerte_upload', {
          st_slug: $('#body').data('structure'),
          encleunik: $elm.data('unik'),
          doc: $elm.data('doc')
        })
        $.ajax({
          type: 'POST',
          processData: false,
          contentType: false,
          url: route,
          data: e.target.result,
          success: function (data) {
            if (data === true) {
              $('#form_' + id).remove()
              $('#column-' + id).html('<i class="fa fa-file"></i> Document uploadé.')
              $loader.hide('slow', function () {
                $bar.animate({
                  width: '0%'
                })
              })
            }
          }
        })
      }
      reader.onprogress = function (e) {
        const avance = Math.floor((e.loaded / fileSize) * 100)
        $bar.animate({
          width: avance + '%'
        })
      }
    })
  })

  // Notification on alert view
  $('.check-alerte').on('click', function () {
    const target = $(this).data('target')
    const route = Routing.generate('st_alerte_check', {
      st_slug: $('#body').data('structure'),
      id: target
    })

    $.get({
      url: route,
      success: function (data) {
        if (data === true) {
          $('#alerte-' + target).slideUp('slow', function () {
            $('#alerte-' + target).remove()
            const nbAlerte = $('#blocAlerte .alert').length
            if (nbAlerte <= 0) {
              $('#blocAlerte').slideUp('slow')
            }
          })
        }
      }
    })
  })

  // Redirect after update state of commande notification
  $('.link-commande-alerte').on('click', function (e) {
    e.preventDefault()
    const target = $(this).data('target')
    const href = $(this).attr('href')
    const route = Routing.generate('st_alerte_check_commande', {
      st_slug: $('#body').data('structure'),
      id: target
    })
    $.get({
      url: route,
      success: function (data) {
        window.location.href = href
      }
    })
  })

  // Confirmation suppression personne (icoe|raccompagnateur)
  $('.confirm-delete').on('click', function (e) {
    e.preventDefault()
    var $this = $(this)

    const onValidation = function () {
      const link = $this.attr('href')
      if (link === undefined) {
        return
      }
      window.location = link
    }

    helpers.confirm({
      title: 'Confirmation requise !',
      content: 'Confirmez-vous la suppression de cette personne ?'
    }, onValidation)
  })

  $('body').on('click', '.delete-document', function () {
    const $elm = $(this)

    const onValidation = function () {
      const url = $elm.data('url')
      const targetId = $elm.data('target')
      const refForNewAdd = $elm.data('ref')
      if (url === undefined) {
        return
      }

      $.get({
        url: url,
        success: function (response) {
          if (response.state !== true) {
            helpers.displayNotification('Information', 'Ce document ne peut-être supprimé', { headerClass: 'bg-danger' })

            return
          }

          $('body #' + targetId).hide('slow', function () {
            $(this).remove()
            if ($('body .' + targetId).length < 5) {
              $('body .' + refForNewAdd).show('slow')
            }
          })

          if (response.hideInProgressAlert !== false) {
            $('body .alert-depot-' + response.hideInProgressAlert).hide('slow')
          }
        }
      })
    }

    helpers.confirm({
      title: 'Confirmation requise !',
      content: 'Confirmez-vous la suppression de ce document ?'
    }, onValidation)
  })

  // Selection des participants dans activité
  $('.btn-action-participant').on('click', function () {
    const target = $(this).data('key')
    if ($('#' + target).is(':checked')) {
      $(this).removeClass('btn-primary').addClass('btn-outline-primary')
      $('#' + target).removeAttr('checked')
    } else {
      $(this).removeClass('btn-outline-primary').addClass('btn-primary')
      $('#' + target).attr('checked', 'checked')
    }
  })

  // Confirmation lors du nettoyage du panier
  $('.clear-panier').on('click', function (e) {
    e.preventDefault()
    var $this = $(this)

    const onValidation = function () {
      const link = $this.attr('href')
      window.location = link
    }

    helpers.confirm({
      title: 'Confirmation requise !',
      content: 'Confirmez-vous la suppression de TOUT VOTRE PANIER ?'
    }, onValidation)
  })

  // Confirmation lors d'une suppression de commande
  $('.delete-commande').on('click', function (e) {
    e.preventDefault()
    const $elm = $(this)
    const onValidation = function () {
      const link = $elm.attr('href')
      window.location.href = link
    }

    helpers.confirm({
      title: 'Confirmation requise !',
      content: 'Confirmez-vous la suppression de votre commande ?'
    }, onValidation)
  })

  $('.toggle-check').on('change', function () {
    var route = Routing.generate('app_backend_form_change', {
      st_slug: tools.getCurrentStructureSlug()
    })
    const blockId = $(this).data('target')
    let isVisible = $('#visible-' + blockId).prop('checked')
    isVisible = isVisible === true ? 1 : 0
    let isRequired = $('#required-' + blockId).prop('checked')
    isRequired = isRequired === true ? 1 : 0
    $.ajax({
      type: 'GET',
      url: route,
      data: 'key=' + blockId + '&visible=' + isVisible + '&required=' + isRequired,
      success: function (data) {
      }
    })
  })

  $('body').on('click', '.btn-target', function () {
    const $btn = $(this)
    const targetId = $btn.data('target')
    if (targetId === undefined) {
      return
    }

    $('body #' + targetId).click()
  })

  const eventClick = (function () {
    if ('touchstart' in document.documentElement) {
      return 'touchstart'
    }
    return 'click'
  })()

  $('body').on(eventClick, '.select-card-activite', function (e) {
    e.stopImmediatePropagation()
    const $btn = $(this)
    selectActivity($btn)
  })

  // résumé panier afficher tableau d'une personne
  $('body').on('click', '.btn-toggle-personne', function () {
    const $button = $(this)
    $('.btn-toggle-personne').removeClass('active')
    $button.addClass('active')
    const target = $(this).data('target')
    if ($('.box-p' + target).is(':visible')) {
      // $('.box-p' + target).addClass('d-none')
    } else {
      $('.box-p').addClass('d-none')
      $('.box-p' + target).removeClass('d-none')
    }
  })

  // résumé panier afficher les inscriptions d'une personne (mois)
  $('.btn-month').on('click', function () {
    const target = $(this).data('target')
    if ($('.table-' + target).is(':visible')) {
      $('.table-' + target).addClass('d-none')
    } else {
      $('.table-month').addClass('d-none')
      $('.table-' + target).removeClass('d-none')
    }
  })

  // raccourci panier
  const $panier = $('.panier-container .panier-nb')

  if ($panier) {
    helpers.loadPanier($panier)
  }

  // Supprimer une inscription à partir de la page de résumé du panier
  $('body').on('click', '.delete-elm-panier', function () {
    var $this = $(this)
    var route = Routing.generate('reservation_panier_remove', {
      st_slug: tools.getCurrentStructureSlug()
    })
    const key = $(this).data('key')
    const mois = $(this).data('month')
    $.ajax({
      type: 'GET',
      url: route,
      data: 'key=' + key,
      success: function (data) {
        const personneCourante = $('.btn-toggle-personne.active').data('target')
        const routePanier = Routing.generate('reservation_panier', {
          st_slug: tools.getCurrentStructureSlug()
        })
        $.post({
          url: routePanier,
          data: { ajax: true },
          success: function (html) {
            $('#sectionPanier').replaceWith(html)
            $('.btn-toggle-personne[data-target="' + personneCourante + '"').trigger('click')
            $('#' + mois + '-tab').trigger('click')
          }
        })
        // if (data.statut !== 200 && data.statut !== 307) {
        //   $('.ajax-response').html('<span class="badge badge-danger p-2">' + data.message + '</span>')
        //   return
        // }
        // if (data.statut === 307) {
        //   document.location.href = window.location.href
        //   return
        // }
        // if (data.removeCarte === true) {
        //   const $cardContainer = $('body #carte-' + data.carteId)
        //   const withPaiementRequis = $cardContainer.data('requis')
        //   const tarifCard = parseFloat($cardContainer.data('tarif'))
        //   if (withPaiementRequis === 1) {
        //     let prValue = parseFloat($('#text-pr').data('value'))
        //     prValue = Number((prValue - tarifCard).toFixed(2))
        //     $('#text-pr').data('value', prValue)
        //     $('#text-pr').html(prValue)
        //   } else {
        //     let nrValue = parseFloat($('#text-nr').data('value'))
        //     nrValue = Number((nrValue - tarifCard).toFixed(2))
        //     $('#text-nr').data('value', nrValue)
        //     $('#text-nr').html(nrValue)
        //   }
        //   $cardContainer.hide('slow', function () {
        //     $cardContainer.remove()
        //     // regarde si il reste des éléments
        //     if ($('body .card-container div').length <= 0) {
        //       $('body .card-container').parent().hide('slow')
        //     }
        //   })
        // }
        // checkResume($this)
        // data.lignesAdhesion.forEach(ligneAdhesion => {
        //   checkResume($('[data-key="' + ligneAdhesion + '"]'))
        // })
      },
      error: function () {
        alert('Une erreur est survenue, veuillez réessayer.')
      }
    })
  })

  // Ajout de l'icône 'oeil' pour les champs password
  $('.form-password').after('<span class="ico-field"><i class="fa fa-eye"></i></span>')

  // clique sur un icône 'oeil'
  $('body').on('click', '.ico-field', function () {
    const $elm = $(this)
    const $parent = $elm.prev()
    if ($parent.attr('type') !== 'password') {
      return
    }
    const $parentElm = document.getElementById($parent.attr('id'))
    $parentElm.type = 'text'
  })

  // Le curseur sort de l'icône 'oeil'
  $('body').on('mouseleave', '.ico-field', function () {
    const $elm = $(this)
    const $parent = $elm.prev()
    if ($parent.attr('type') !== 'text') {
      return
    }
    const $parentElm = document.getElementById($parent.attr('id'))
    $parentElm.type = 'password'
  })

  // Un champ mot de passe est focus, affichage des instructions
  $('body').on('focus', '.form-password', function () {
    const $elm = $(this)
    if ($elm.hasClass('form-confirm')) {
      return
    }
    if ($('.form-notification').is(':visible')) {
      $('.form-notification').slideUp('slow')
    } else {
      $('.form-notification').addClass('hide')
    }

    $('.form-notification').html(`
      Votre mot de passe doit être composé d'au moins 8 caractères, <br />
      Il doit comporter au moins 1 majuscule, <br />
      Et l'un des caractères suivants : $, !, ?, %
    `)
    $('.form-notification').slideDown()
  })

  $('.form-password').on('blur', function () {
    $('.form-notification').slideUp('slow').html('')
  })

  let ctrlDown = false

  $(document).keydown(function (e) {
    if (e.keyCode === 17 || e.keyCode === 91) ctrlDown = true
  }).keyup(function (e) {
    if (e.keyCode === 17 || e.keyCode === 91) ctrlDown = false
  })

  $('.no-paste').on('keydown', function (e) {
    if (e.keyCode === 86 && ctrlDown) {
      e.preventDefault()
    }
  })

  $('.no-paste').contextmenu(function (e) {
    e.preventDefault()
  })

  $('body').on('click', '.btn-close', function () {
    const $btn = $(this)
    const target = $btn.data('target')
    $(target).fadeOut('slow')
  })

  $('.select-filter-global, .select-filter-multiple').select2({
    language: 'fr'
  })

  $('.select2-jx-data').select2({
    ajax: {
      url: $('.select2-jx-data').data('feeds'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term
        }

        return query
      }
    },
    language: 'fr',
    minimumInputLength: 3
  })

  let defaultPlaceholderMultiple = 'Sélectionner une ou plusieurs valeur(s)'

  $('.select2-multiple').each(function (i, elm) {
    const elmPlaceholder = $(elm).data('placeholder')

    if (elmPlaceholder !== 'undefined') {
      defaultPlaceholderMultiple = elmPlaceholder
    }

    $(elm).select2({
      placeholder: defaultPlaceholderMultiple,
      language: 'fr'
    })
  })

  $('#userswitcher').on('change', function () {
    const prop = $(this).val()
    let url = window.location
    url = url + '?_switch_to_user=' + prop
    window.location.href = url
  })

  $('body').on('click', '.btn-submit-payement', function (e) {
    e.preventDefault()
    const $elm = $(this)
    const $avoirBtns = $('.avoir-btn')
    const nbElmChecked = $('.avoir-btn.active').length

    if (nbElmChecked > 0) {
      const onValidation = function () {
        $avoirBtns.removeClass('active')

        if ($elm.hasClass('link')) {
          $elm.click()

          return
        }

        $('#payment-form').submit()
      }

      const onClose = function () {

      }

      helpers.confirm({
        title: 'Des avoirs sont en attente de validation',
        content: 'Il semblerait que vous avez sélectionné des avoirs mais qu\'ils ne sont pas validé, souhaitez-vous quand même procéder au paiement ?'
      }, onValidation, onClose)

      return
    }

    if ($elm.hasClass('link')) {
      $elm.click()

      return
    }

    $('#payment-form').submit()
  })

  $('.select-filter-global .select2-container').addClass('form-control')

  // avoir remove btn
  $('.remove-avoir').on('click', function (e) {
    e.preventDefault()
    const $elm = $(this)
    const targetId = $elm.data('target')
    const transactionId = $elm.data('transaction')
    const route = Routing.generate('paiement_remove_avoir', {
      st_slug: $('#body').data('structure'),
      avoir: targetId,
      transaction: transactionId
    })
    $.get({
      url: route,
      success: function () {
        const location = window.location.pathname + '?transaction=' + transactionId
        window.location = location
      }
    })
  })

  // avoir target btn
  $('.target-btn').on('click', function (e) {
    e.preventDefault()
    const $elm = $(this)
    const target = $elm.data('target')
    const validTarget = `<i class="fa fa-check"></i>`
    const $target = $('#' + target)
    if ($target.length < 1) {
      return
    }
    if ($target.is(':checked')) {
      $elm.removeClass('active')
      $elm.html('Utiliser')
      $target.prop('checked', false)
    } else {
      $elm.addClass('active')
      $elm.prepend(validTarget)
      $target.prop('checked', true)
    }
    checkAvoir()
  })

  // multiple payment gestion
  if ($('.badge-mult-pay-range').length > 0) {
    tools.setupMultiplePayments()
  }
  $('.fired-mult-pay').on('click touch', function () {
    tools.setupMultiplePayments()
  })
  $('body').on('change', '.select-prev-day', function () {
    const $select = $(this)
    $('body .mult-pay-transaction-details').data('prev-day', $select.val())
    tools.setupMultiplePayments()
  })
  $('.badge-mult-pay-range').on('click touch', function () {
    const $btn = $(this)

    if ($btn.hasClass('active')) {
      return
    }

    const $currentBtn = $('.badge-mult-pay-range.active')
    $currentBtn.removeClass('active btn-outline-success').addClass('btn-outline-danger')
    $btn.removeClass('btn-outline-danger').addClass('active btn-outline-success')
    tools.setupMultiplePayments()
  })

  // Nouveau dossier
  // step 1
  $('#start-new-dir').on('click', function () {
    const route = Routing.generate('app_nouveau_dossier_create_adulte')

    $('#container-new-dir').load(route, function () {
      $('.select-filter-global, .select-filter-multiple').select2({
        language: 'fr'
      })
    })
  })

  // step 2 - create  adulte
  $('body').on('click', '#create-adulte', function (e) {
    e.preventDefault()

    const form = document.getElementById('form-create-adulte')
    const formData = new FormData(form)
    const route = Routing.generate('app_nouveau_dossier_create_adulte')
    $('.select-filter-global, .select-filter-multiple').select2({
      language: 'fr'
    })

    $.post({
      url: route,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        $('#container-new-dir').html(response.content)
        $('.select-filter-global, .select-filter-multiple').select2({
          language: 'fr'
        })
        if (response.state === true) {
          const route = Routing.generate('app_nouveau_dossier_state')
          $('#state-family').load(route, function () {
            $('.select-filter-global, .select-filter-multiple').select2({
              language: 'fr'
            })
          })
          $('#state-family').addClass('col-lg-4')
          $('#main-container').removeClass('col-12').addClass('col-lg-8')
        }
        if (response.responsableIsCreated === true) {
          $('#form-adulte-action').prepend('<button type="button" class="btn btn-warning btn-sm" id="passing">Ignorer</button>')
        }
      }
    })
  })

  // Step 3 - Passing create second adulte
  $('body').on('click', '#passing', function (e) {
    e.preventDefault()

    const route = Routing.generate('app_nouveau_dossier_create_enfant')
    $('#container-new-dir').load(route)
  })

  // Step 4 - Create enfant
  $('body').on('click', '#create-enfant', function (e) {
    e.preventDefault()

    const form = document.getElementById('form-create-enfant')
    const formData = new FormData(form)
    const route = Routing.generate('app_nouveau_dossier_create_enfant')

    $.post({
      url: route,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        $('#container-new-dir').html(response.content)
        if (response.state === true) {
          const route = Routing.generate('app_nouveau_dossier_state')
          $('#state-family').load(route)
        }
      }
    })
  })

  // Step 5 - Passing create enfant
  $('body').on('click', '#passingEnfant', function (e) {
    e.preventDefault()

    const form = document.getElementById('form-create-enfant')
    const formData = new FormData(form)
    const route = Routing.generate('app_nouveau_dossier_create_enfant', {
      passing: true
    })

    $.post({
      url: route,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        $('#container-new-dir').html(response.content)
        if (response.state === true) {
          const route = Routing.generate('app_nouveau_dossier_recapitulatif')
          $('#global-container-new-dir').load(route)
        }
      }
    })
  })

  $('body').on('click', '#loadRecap', function () {
    const route = Routing.generate('app_nouveau_dossier_recapitulatif')
    $('#global-container-new-dir').load(route)
  })

  // Step 6 - Valider
  $('body').on('click', '#valider-dossier', function (e) {
    e.preventDefault()

    const $btn = $(this)

    if ($btn.hasClass('initialized')) {
      return
    }

    $btn.addClass('initialized')

    const route = Routing.generate('app_nouveau_dossier_valider_dossier')
    $.post({
      url: route,
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.state === true) {
          const finishRoute = Routing.generate('app_nouveau_dossier_finish')
          $('#global-container-new-dir').load(finishRoute)
        } else {
          const errorRoute = Routing.generate('app_nouveau_dossier_error', {
            message: response.message
          })
          $('#global-container-new-dir').load(errorRoute)
        }
      }
    })
  })

  // Copy Resp. adresse
  $('body').on('click', '#copyRespoAdresse', function () {
    const route = Routing.generate('app_nouveau_dossier_copy_responsable_adresse')

    $.get({
      url: route,
      contentType: false,
      processData: false,
      success: function (data) {
        $('body #new_adulte_adresse1').val(data.adresse1)
        $('body #new_adulte_adresse2').val(data.adresse2)
        $('body #new_adulte_adresse3').val(data.adresse3)

        $('body #new_adulte_adresseCpcle').val(data.cpcle)
        $('body #new_adulte_adresseCpcle').select2().trigger('change')
      }
    })
  })

  // Fiche enfant
  // Copy same mutuelle
  $('body').on('change', '#copy_enfant_mutuelle', function () {
    const $select = $(this)
    const enfantId = $select.val()

    const route = Routing.generate('st_famille_copy_mutuelle_enfant', {
      st_slug: tools.getCurrentStructureSlug()
    })

    $.get({
      url: route,
      data: 'enfantId=' + enfantId,
      success: function (response) {
        if (response.state === false) {
          helpers.displayNotification('Une erreur est survenue', 'Impossible de récupérer les informations', {
            headerClass: 'bg-danger'
          })
          $('#copy_enfant_mutuelle').val('')

          return
        }

        if (response.data.cmu === true) {
          $('#personne_enfant_enfant_cmu').prop('checked', 'checked')
        }

        $('#personne_enfant_enfant_mutuelle_nom').val(response.data.nom)
        $('#personne_enfant_enfant_mutuelle_numero').val(response.data.numero)
        $('#personne_enfant_enfant_mutuelle_adresse1').val(response.data.adresse1)
        $('#personne_enfant_enfant_mutuelle_adresse2').val(response.data.adresse2)
        $('#personne_enfant_enfant_mutuelle_cpcle').val(response.data.cpcle)
        $('#personne_enfant_enfant_mutuelle_cpcle').select2().trigger('change')

        $('#copy_enfant_mutuelle').val('')

        helpers.displayNotification('Données copiées', 'Les informations de la mutuelle ont été copiées', {
          headerClass: 'bg-info'
        })
      }
    })
  })

  // Copy same assurance
  $('body').on('change', '#copy_enfant_assurance', function () {
    const $select = $(this)
    const enfantId = $select.val()

    const route = Routing.generate('st_famille_copy_assurance_enfant', {
      st_slug: tools.getCurrentStructureSlug()
    })

    $.get({
      url: route,
      data: 'enfantId=' + enfantId,
      success: function (response) {
        if (response.state === false) {
          helpers.displayNotification('Une erreur est survenue', 'Impossible de récupérer les informations', {
            headerClass: 'bg-danger'
          })
          $('#copy_enfant_assurance').val('')

          return
        }

        $('#personne_enfant_enfant_assurance_nom').val(response.data.nom)
        $('#personne_enfant_enfant_assurance_contrat').val(response.data.contrat)
        $('#personne_enfant_enfant_assurance_debut').val(response.data.start)
        $('#personne_enfant_enfant_assurance_fin').val(response.data.end)
        $('#personne_enfant_enfant_assurance_adresse1').val(response.data.adresse1)
        $('#personne_enfant_enfant_assurance_adresse2').val(response.data.adresse2)
        $('#personne_enfant_enfant_assurance_cpcle').val(response.data.cpcle)
        $('#personne_enfant_enfant_assurance_cpcle').select2().trigger('change')

        $('#copy_enfant_assurance').val('')

        helpers.displayNotification('Données copiées', 'Les informations de l\'assurance ont été copiées', {
          headerClass: 'bg-info'
        })
      }
    })
  })

  // Fiche enfant - auto launch modal when fiche medical is updated
  const $modalCanBeOpened = $('body .modal-fiche-sanitaire.go-open')

  if ($modalCanBeOpened.length > 0) {
    $modalCanBeOpened.show()
  }

  $('body').on('click', '.close.t-modal', function () {
    const $btn = $(this)
    const target = $btn.data('target')

    if (target === undefined || target.length <= 0) {
      return
    }

    $('body #' + target).hide('slow')
  })

  // Tableau facture
  $('body').on('click touch', '.table-filter', function (e) {
    e.preventDefault()

    const $elm = $(this)
    const tableId = $elm.data('table')
    let key = $elm.data('key')
    let value = $elm.data('value')

    const $table = $('body #' + tableId)

    if ($table.length <= 0) {
      console.log('table with id : #' + tableId + ' not found inside the DOM')

      return
    }

    const feedsURI = $table.data('feeds')

    if (feedsURI === undefined) {
      console.log('table associated to .table-filter need feeds uri')

      return
    }

    let params = ''

    let currentPage = $table.data('current-page')
    if ($elm.hasClass('btn-pagination')) {
      currentPage = $elm.data('page')
      const $activeFilter = $('.table-filter-children.active').length > 0 ? $('.table-filter-children.active') : $('.table-filter.active:not(.btn-pagination)')
      key = $activeFilter.data('key')
      value = $activeFilter.data('value')
    } else {
      const childrens = $elm.data('childrens')

      if (childrens !== undefined) {
        $('body .table-filter-children').css('display', 'none')
        $('body .' + childrens).css('display', 'inline-block')
      }

      const parent = $elm.data('parent')
      $('body .table-filter:not(.btn-pagination)').removeClass('active')

      if (parent !== undefined) {
        $('body .' + parent).addClass('active')
      }

      $elm.addClass('active')
    }

    if (key !== undefined) {
      params = '?key=' + key + '&value=' + value
    }
    params += (params.length > 0 ? '&' : '?') + 'page=' + currentPage

    $.get({
      url: feedsURI + params,
      success: function (data) {
        $('body #' + tableId + ' tbody tr').remove()
        if (data.items) {
          for (let i = 0; i < data.items.length; i++) {
            const facture = data.items[i]
            let dl = Routing.generate('st_facture_download', {
              st_slug: tools.getCurrentStructureSlug(),
              facture: facture.id
            })
            if (tableId === 'reglement-liste') {
              dl = Routing.generate('st_reglement_download', {
                st_slug: tools.getCurrentStructureSlug(),
                reglement: facture.id
              })
            }

            let tr = '<tr></tr>'

            if (data.type === 'reglement') {
              tr = `
              <tr>
                <td>` + facture.id + `</td>
                <td>` + moment(facture.created_at).format('DD/MM/YYYY') + `</td>
                <td>` + parseFloat(facture.montant).toFixed(2) + ` &euro;</td>
                <td>` + facture.type_reglement.libelle + `</td>
                <td><a href="` + dl + `" target="_blank" title="Visualiser le PDF ?"><i class="fa fa-file-pdf-o font-size-18"></i></a></td>
              </tr>
              `
            } else {
              tr = `
                <tr>
                  <td>` + facture.id + `</td>
                  <td>` + facture.numero + `</td>
                  <td>` + moment(facture.created_at).format('DD/MM/YYYY') + `</td>
                  <td>` + parseFloat(facture.montant).toFixed(2) + ` &euro;</td>
                  <td>` + parseFloat(facture.solde).toFixed(2) + ` &euro;</td>
                  <td><a href="` + dl + `" target="_blank" title="Visualiser le PDF ?"><i class="fa fa-file-pdf-o font-size-18"></i></a></td>
                </tr>
                `
            }

            $('body #' + tableId + ' tbody').append(tr)
          }

          $('body .pagination-wrapper .btn.btn-pagination').remove()

          for (let j = 0; j < data.total_pages; j++) {
            const nbPage = j + 1
            const isActive = nbPage === currentPage ? 'active' : ''
            const pgn = `
              <button class="btn btn-outline-facture table-filter btn-pagination ` + isActive + ` d-inline-block text-center btn-sm rounded"
              data-page="` + nbPage + `"
              data-table="` + tableId + `">
              ` + nbPage + `
              </button>
            `

            $('body .pagination-wrapper').append(pgn)
          }
        }
      }
    })
  })
})

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

function checkAvoir() {
  const $montant = $('#montant-transaction')
  if ($montant.length < 1) {
    return
  }
  const montantTransaction = $montant.data('value')
  let montantAvoir = 0
  const $avoirFields = $('.avoir-select')
  $avoirFields.each(function (n, elm) {
    const $elm = $(elm)
    if (!$elm.is(':checked')) {
      return
    }
    montantAvoir += parseFloat($elm.data('montant'))
  })
  montantAvoir = montantAvoir.toFixed(2)

  const reste = montantTransaction - montantAvoir

  if (montantAvoir > montantTransaction || reste < 0 || (reste < 1 && reste > 0)) {
    $('#paiement-section').fadeOut()
    $('#validateAvoir').fadeOut()
    $('.avoir-response').html('Attention, le montant des avoirs ne peut pas dépasser le montant à payer et le montant minimum de commande est de 1 &euro;.')
  } else {
    $('#paiement-section').slideDown()
    $('#validateAvoir').fadeIn()
    $('.avoir-response').html('Montant des avoirs sélectionnés : <b>' + montantAvoir + '&euro;</b>')
  }
}

// Lors d'une suppression d'un élément du panier appeler cette fonction avec référence sur l'élément
// function checkResume (element) {
//   const key = $(element).data('key')
//   const month = $(element).data('month')
//   const tooltip = $(element).attr('aria-describedby')
//   const p = $(element).data('p')
//   let nbErrors = parseInt($('.month-errors-print-' + month).html())
//   const hasError = $(element).data('error')
//   const tarif = parseFloat($(element).data('tarif'))
//   const pr = $(element).data('pr')

//   // Si l'élément fait référence à une erreur
//   if (hasError === 1) {
//     nbErrors--
//     $('.month-errors-print-' + month).html(nbErrors)
//   }

//   // Tooltip hide fix
//   $('#' + tooltip).fadeOut()

//   // Cache la ligne (tr)
//   $('.p-' + key).hide('slow', function () {
//     // Supprime la ligne (tr)
//     $(this).remove()
//     if (pr === 1) {
//       let prValue = parseFloat($('#text-pr').data('value'))
//       prValue = Number((prValue - tarif).toFixed(2))
//       if (prValue === 0) {
//         $('#text-pr').parent().hide()
//       } else {
//         $('#text-pr').data('value', prValue)
//         $('#text-pr').html(prValue)
//       }
//     } else {
//       let nrValue = parseFloat($('#text-nr').data('value'))
//       nrValue = Number((nrValue - tarif).toFixed(2))
//       if (nrValue === 0) {
//         $('#text-nr').parent().hide()
//       } else {
//         $('#text-nr').data('value', nrValue)
//         $('#text-nr').html(nrValue)
//       }
//     }
//     // * Compte le nombre de tr dans le tableau qui ont la poubelle pour supprimer la ligne (sinon c'est une adhesion)
//     // * Si nous sommes en type adhésion famille alors une ligne d'adhésion peut rester sur une personne sans autre lignes
//     // * s'il y a une autre personne qui elle à au moins une ligne d'adhésion
//     // const nbElm = $('.table-' + month + ' tr .delete-elm-panier').length
//     const nbElm = $('.table-' + month + ' tr').length
//     if (nbElm > 0) {
//       return
//     }
//     // Si <= 1 élément suppression de ce tableau
//     $('.tab-pane-month-' + month).hide('slow', function () {
//       $('.nav-month-' + month).hide('slow', function () {
//         $('.tab-pane-month-' + month).remove()
//         $('.nav-month-' + month).remove()
//         // Combien d'onglet (mois)
//         const nbLi = $('ul.nav-tabs-' + p + ' li').length
//         // Si plus d'onglet, on supprime le bouton, sinon le prochain onglet devient actif
//         if (nbLi <= 0) {
//           $('button[data-target="' + p + '"]').hide('slow', function () {
//             $(this).remove()
//             // Check si il reste encore des personnes
//             if ($('.btn-toggle-personne').length < 1) {
//               document.location.href = window.location.href
//             }
//           })
//         } else {
//           $('ul.nav-tabs-' + p + ' li .nav-link').first().addClass('active')
//           var tabId = $('ul.nav-tabs-' + p + ' li .nav-link').attr('data-month')
//           $('.btn-toggle-personne.btn-personne-' + p).click()
//           $('ul.nav-tabs-' + p + ' li').first().attr('aria-describedby', 'true')
//           $('#' + tabId).addClass('active')
//         }
//       })
//     })
//   })
// }

function selectActivity ($btn) {
  const targetId = $btn.data('target')

  if (targetId === undefined) {
    return
  }

  const $target = $('body #' + targetId)
  if ($target.length <= 0) {
    return
  }

  const activiteCardId = $target.attr('id')
  const $vsp = $('body #card-' + activiteCardId + ' .vsp')
  const $card = $('body #card-' + activiteCardId)

  if ($target.is(':checked')) {
    $target.prop('checked', false)
    $card.css('border', '')
    $vsp.removeClass('bg-success').addClass('bg-danger').removeClass('text-right')
    $vsp.children('i').removeClass('fa-check-circle').addClass('fa-times-circle')
    return
  }
  $card.css('border', '3px solid #B5D56A')
  $vsp.removeClass('bg-danger').addClass('bg-success').addClass('text-right')
  $vsp.children('i').removeClass('fa-times-circle').addClass('fa-check-circle')
  $target.prop('checked', true)
}

// Initialisation Timepicker
$(document).ready(function () {
  helpers.initialiserTimepicker()
})

// caroussel activite accueil
$(document).ready(function () {
  $('.carousel-inner > .carousel-item:first').addClass('active')
  $('.carousel').carousel({
    interval: 5000
  })
})

// Carousel multi élément responsive pour afficher la liste des actu au dessus de la page d'accueil
$('#carouselActuMulti').carousel({
  interval: 5000
})

$('#carouselActuMulti.carousel .carousel-item').each(function () {
  var minPerSlide = 20
  var next = $(this).next()
  if (!next.length) {
    next = $(this).siblings(':first')
  }
  next.children(':first-child').clone().appendTo($(this))
  for (var i = 0; i < minPerSlide; i++) {
    next = next.next()
    if (!next.length) {
      next = $(this).siblings(':first')
    }
    next.children(':first-child').clone().appendTo($(this))
  }
});

// En fonction du nombre d'éléments et de la dimension de la fenêtre on adapte le carousel
// En mobile il faut au moins 2 éléments pour le carousel
// Sur PC avec une bonne résolution il faut au moins 4 éléments
// Ceci n'étant pas possible à faire n twig, nous le gérons en JS
$(window).on('load', function () {
  const nbActu = parseInt($('.carousel-load').data('nb-actu'))
  const largeur = window.innerWidth
  const col = 12 / nbActu
  if (nbActu > 1 && ((nbActu < 4 && largeur >= 1200) || (nbActu < 3 && largeur >= 991 && largeur < 1200))) {
    $('.carousel-control-prev.w-auto').hide()
    $('.carousel-control-next.w-auto').hide()
    $('.carousel-load .carousel-load-size').removeClass("col-lg-6 col-xl-4")
    $('.carousel-load .carousel-load-size').addClass("col-lg-" + col + " col-xl-" + col)
    $('.carousel-load').carousel('pause')
  }
});

// permet de mettre une alerte lorsque certains documents sont sur le point d'expirer
$(document).ready(function () {
  if (!tools.getCurrentStructureSlug()) {
    return
  }

  const route = Routing.generate('app_suividedocument_isdocsexpired', {
    st_slug: $('body').data('structure')
  })

  $.get({
    url: route,
    success: function (data) {
      const cook = Cookies.get('documentexpire')
      if (data.message && data.message === true) {
        if (cook === undefined) {
          var routedoc = Routing.generate('app_suividedocument_index', {
            st_slug: $('body').data('structure')
          })
          $('#modalLabel').html('Attention, document expiré')
          $('#modalbody').html('<div id="div1">  Au moins l\'un de vos documents est sur le point ou est expiré, veuillez le mettre à jour \
            <br/><br/><a href="' + routedoc + '" class="btn btn-primary stretched-link">Mettre à jour le document</a></b></div>')
          $('#modal').modal('show')
          var exp = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          Cookies.set('documentexpire', true, { expires: exp })
        }
      } else {
        Cookies.remove('documentexpire')
      }
    }
  })
})

$(document).ready(function () {
  if (!tools.getCurrentStructureSlug()) {
    return
  }

  const $parent = $('#accordion-error')
  if (Object.keys($parent).length !== 0) {
    $('#modalLabel').html('Certaines inscriptions n\'ont pas pu être traitées')
    $('#modalbody').append($('#accordion-error').children())
    $('#modal').modal('show')
  }
})

// On va afficher au niveau de l'étape une des réservations la popup de blocage si des factures sont en attente
// La volonté et de prévenir dès le départ que l'inscription ne sera pas possible plutôt que de laisser la famille commencer sa sasie
$(document).ready(function () {
  if (!tools.getCurrentStructureSlug()) {
    return
  }

  if ($('#blocage').length && $('#blocage').html() == 1) {

    $('#modalLabel').html('Attention, facture en attente')

    let texte = '<div id="div1">'
    // On laisse le texte par défaut si la valeur du champ en base est null
    if ($('#texte_blocage').html() === "") {
      texte += 'Vous avez une ou des facture(s) en attente(s), vous ne pouvez pas vous inscrire'
    } else {
      texte += $('#texte_blocage').html()
    }
    texte += '</div>'
    $('#modalbody').html(texte)

    $('#modal').modal('show')
  }

  if ($('#blocageAdhesion').length && $('#blocageAdhesion').html() == 1) {

    $('#modalLabel').html('Commande en cours')

    const texteAdhesion = '<div id="div1">' + $('#texteBlocageAdhesion').html() + '</div>'
    $('#modalbody').html(texteAdhesion)

    $('#modal').modal('show')
  }
})

$('body').on('click', 'button[id^=btnMajQuotient]', function () {
  const button = $(this)
  const type = button.data('type')
  const indice = button.data('indice')
  if (type === 'cnaf_quotient_familial') {
    const route = Routing.generate('informations_cnaf')

    $.post({
      url: route,
      data: { st_slug: tools.getCurrentStructureSlug() },
      beforeSend: function () {
        button.prop('disabled', true)
        button.find('.spinner-border').removeClass('d-none')
      },
      success: function (data) {
        button.find('.spinner-border').addClass('d-none');
        button.prop('disabled', false)
        // retour valide
        if (data.status === 200) {
          if (data.famille.quotientFamilial) {
            button.parent().siblings().find('input').val(data.famille.quotientFamilial.toFixed(2))
          }
          $('#personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient' + indice).prop("checked", false)
          $('#personne_adulte_adulte_quotientAdulte_quotient' + indice + 'Actualise').val(true)
        } else {
          const errorMessageCode = data.errorMessage + ' (' + data.status + ')'
          $('#errorMAJQuotient' + indice).html(errorMessageCode)
          $('#errorMAJQuotient' + indice).removeClass('d-none')
          // Le compte allocataire n'existe pas ou plus, dans ce cas on vide les infos caf n° allocataire + clé caf + régime
          // Je ne sais pas si cette fonctionnalité est pertinente car on ne sait pas vraiment à quelle valeur correspond le code postal
          // En cas de déménagement comment cela se passe
          // Je pense que l'on va générer + de mécontentement avec ça
          if (data.status === 404) {
            // const route = Routing.generate('st_famille_remove_infos_caf', {
            //   st_slug: $('body').data('structure'),
            //   personne: data.personne
            // })
            // $.get({
            //   url: route,
            // })
          }
        }
      }
    })
  } else {
    $('#btnMajRevenuDGFIP').data('indice', indice)
  }
})

$('body').on('click', '#btnMajRevenuDGFIP', function () {
  const thisButton = $(this)
  const button = $('#btnMajQuotient' + $(this).data('indice'))
  const type = button.data('type')
  const indice = button.data('indice')
  const route = Routing.generate('informations_dgfip')

  $.post({
    url: route,
    data: { numeroFiscal: $('#numeroFiscal').val(), referenceAvis: $('#referenceAvis').val(), st_slug: tools.getCurrentStructureSlug() },
    beforeSend: function () {
      thisButton.prop('disabled', true)
      thisButton.find('.spinner-border').removeClass('d-none')
    },
    success: function (data) {
      thisButton.find('.spinner-border').addClass('d-none');
      thisButton.prop('disabled', false)
      // retour valide
      if (data.status === 200) {
        let revenu = null
        if (data.avisImposition) {
          if (type === 'dgfip_revenu_fiscal_reference') {
            if (data.avisImposition.revenuFiscalReference) {
              revenu = data.avisImposition.revenuFiscalReference
            }
          } else if (type === 'dgfip_revenu_brut_global') {
            if (data.avisImposition.revenuBrutGlobal) {
              revenu = data.avisImposition.revenuBrutGlobal
            }
          } else if (type === 'dgfip_revenu_imposable') {
            if (data.avisImposition.revenuImposable) {
              revenu = data.avisImposition.revenuImposable
            }
          }
          button.parent().siblings().find('input').val(revenu.toFixed(2))
          $('#modalDGFIP').modal('hide');
        }
        $('#personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient' + indice).prop("checked", false)
        $('#personne_adulte_adulte_quotientAdulte_quotient' + indice + 'Actualise').val(true)
      } else {
        $('#errorMAJRevenu').html(data.errorMessage)
        $('#errorMAJRevenu').removeClass('d-none')
      }
    }
  })
})

// Récupération des infos CNAF pour les mettre dans des cartes
$('body').on('click', '#btnCnaf', function () {
  $('#cartes_cnaf').empty()
  $('#cafErrorMessage').addClass('d-none')
  $('#cafErrorMessage').empty()
  const route = Routing.generate('informations_cnaf')

  $.post({
    url: route,
    data: { numeroAllocataire: $('#numeroAllocataireInput').val(), codePostal: $('#codePostalInput').val(), st_slug: tools.getCurrentStructureSlug() },
    beforeSend: function () {
      $('#btnCnaf').prop('disabled', true)
      $('#btnCnaf .spinner-border').removeClass('d-none');
    },
    success: function (data) {
      $('#btnCnaf .spinner-border').addClass('d-none');
      $('#btnCnaf').prop('disabled', false)
      // retour valide
      if (data.status === 200) {
        // Je vais créer des cartes pour permettre un remplissage rapide à l'inscription
        if (data.famille) {
          if ($('#cartes_cnaf').data('type') === 'adulte') {
            // On met le numéro allocataire et le code postal caf dans les champs du formulaire si la réponse est valide
            $('#new_adulte_numeroAllocataireCAF').val($('#numeroAllocataireInput').val())
            $('#new_adulte_codePostalCAF').val($('#codePostalInput').val())
            $('#new_adulte_quotientCAF').parent().removeClass('d-none')
            if (data.famille.quotientFamilial) {
              $('#new_adulte_quotientCAF').val(data.famille.quotientFamilial)
            }
            // On va mettre tout le contenu de la famille dans une balise data pour conserver les infos de page en page
            $('#new_adulte_famille').val(JSON.stringify(data.famille))
            let codePostal = ''
            let ville = ''
            let rue = ''
            let lieuDit = ''
            let complementIdentiteGeo = ''
            if (data.famille.adresse) {
              if (data.famille.adresse.codePostalVille) {
                let codePostalVille = data.famille.adresse.codePostalVille.split(' ')
                if (codePostalVille.length > 1) {
                  codePostal = codePostalVille[0]
                  codePostalVille.shift()
                  ville = codePostalVille.join(' ')
                }
              }

              if (data.famille.adresse.numeroRue) {
                rue = data.famille.adresse.numeroRue
              }

              if (data.famille.adresse.lieuDit) {
                lieuDit = data.famille.adresse.lieuDit
              }

              if (data.famille.adresse.complementIdentiteGeo) {
                complementIdentiteGeo = data.famille.adresse.complementIdentiteGeo
              }
            }

            if (data.famille.allocataires) {
              data.famille.allocataires.forEach((allocataire, compteur) => {
                let civiliteCode = ''
                let civiliteTexte = ''
                if (allocataire.sexe) {
                  if (allocataire.sexe.toUpperCase() === 'F') {
                    civiliteCode = 1
                    civiliteTexte = 'Mme'
                  } else if (allocataire.sexe.toUpperCase() === 'M') {
                    civiliteCode = 3
                    civiliteTexte = 'M.'
                  }
                }

                let nom = ''
                let prenom = ''
                if (allocataire.nomPrenom) {
                  const arrayNomPrenom = allocataire.nomPrenom.split(' ')
                  if (arrayNomPrenom.length === 2) {
                    nom = arrayNomPrenom[0]
                    prenom = arrayNomPrenom[1]
                  }
                }

                $('#cartes_cnaf').append('<div id="adulte_' + compteur + '" class="card px-0 mx-1" data-toggle="tooltip" title="Cliquez ici pour pré-remplir les champs ci-dessous"></div>')
                $('#adulte_' + compteur).append('<h4 class="h5 card-header bg-family"><i class="fa fa-id-badge"></i> Adulte</h4>')
                $('#adulte_' + compteur).append('<div id="cardBodyAdulte" class="card-body">')
                $('#adulte_' + compteur).find('#cardBodyAdulte').append('<div id="identite" class="d-flex"></div>')
                $('#adulte_' + compteur).find('#identite').append('<div id="civilite" class="mr-1" data-value="' + civiliteCode + '">' + civiliteTexte + '</div>')
                if (nom === '' && prenom === '') {
                  $('#adulte_' + compteur).find('#identite').append('<div id="nomPrenom">' + allocataire.nomPrenom + '</div>')
                } else {
                  $('#adulte_' + compteur).find('#identite').append('<div id="nom" class="mr-1" data-value="' + nom + '">' + nom + '</div>')
                  $('#adulte_' + compteur).find('#identite').append('<div id="prenom" data-value="' + prenom + '">' + prenom + '</div>')
                }
                $('#adulte_' + compteur).find('#cardBodyAdulte').append('<div id="adresse"></div>')
                $('#adulte_' + compteur).find('#adresse').append('<div id="rue" data-value="' + rue + '">' + rue + '</div>')
                $('#adulte_' + compteur).find('#adresse').append('<div id="lieuDit" data-value="' + lieuDit + '">' + lieuDit + '</div>')
                $('#adulte_' + compteur).find('#adresse').append('<div id="complementIdentiteGeo" data-value="' + complementIdentiteGeo + '">' + complementIdentiteGeo + '</div>')
                $('#adulte_' + compteur).find('#adresse').append('<div class="d-flex"></div>')
                $('#adulte_' + compteur).find('#adresse div.d-flex').append('<div id="codePostal" class="mr-1" data-value="' + codePostal + '">' + codePostal + '</div>')
                $('#adulte_' + compteur).find('#adresse div.d-flex').append('<div id="ville" data-value="' + ville + '">' + ville + '</div>')
              });
              $('#cartes_cnaf').removeClass('d-none')
            }
          } else if ($('#cartes_cnaf').data('type') === 'enfant') {
            if (data.famille.enfants) {
              data.famille.enfants.forEach((enfant, compteur) => {
                let nom = ''
                let prenom = ''
                if (enfant.nomPrenom) {
                  const arrayNomPrenom = enfant.nomPrenom.split(' ')
                  if (arrayNomPrenom.length === 2) {
                    nom = arrayNomPrenom[0]
                    prenom = arrayNomPrenom[1]
                  }
                }

                let sexeCode = ''
                let sexeTexte = ''
                if (enfant.sexe) {
                  if (enfant.sexe.toUpperCase() === 'F') {
                    sexeCode = 2
                    sexeTexte = 'Mme'
                  } else if (enfant.sexe.toUpperCase() === 'M') {
                    sexeCode = 1
                    sexeTexte = 'M.'
                  }
                }

                let dateDeNaissance = ''
                let dateDeNaissanceFormatee = ''
                if (enfant.dateDeNaissance) {
                  dateDeNaissance = enfant.dateDeNaissance.substring(4) + "-" + enfant.dateDeNaissance.substring(2, 4) + "-" + enfant.dateDeNaissance.substring(0, 2)
                  dateDeNaissanceFormatee = enfant.dateDeNaissance.substring(0, 2) + "/" + enfant.dateDeNaissance.substring(2, 4) + "/" + enfant.dateDeNaissance.substring(4)
                }

                $('#cartes_cnaf').append('<div id="enfant_' + compteur + '" class="card px-0 mx-1" data-toggle="tooltip" title="Cliquez ici pour pré-remplir les champs ci-dessous"></div>')
                $('#enfant_' + compteur).append('<h4 class="h5 card-header bg-family"><i class="fa fa-child"></i> Enfant</h4>')
                $('#enfant_' + compteur).append('<div id="cardBodyEnfant" class="card-body">')
                $('#enfant_' + compteur).find('#cardBodyEnfant').append('<div id="identite" class="d-flex"></div>')
                $('#enfant_' + compteur).find('#identite').append('<div id="sexe" class="mr-1" data-value="' + sexeCode + '">' + sexeTexte + '</div>')
                if (nom === '' && prenom === '') {
                  $('#enfant_' + compteur).find('#identite').append('<div id="nomPrenom">' + enfant.nomPrenom + '</div>')
                } else {
                  $('#enfant_' + compteur).find('#identite').append('<div id="nom" class="mr-1" data-value="' + nom + '">' + nom + '</div>')
                  $('#enfant_' + compteur).find('#identite').append('<div id="prenom" data-value="' + prenom + '">' + prenom + '</div>')
                }
                $('#enfant_' + compteur).find('#cardBodyEnfant').append('<div id="dateDeNaissance" class="mr-1" data-value="' + dateDeNaissance + '">' + dateDeNaissanceFormatee + '</div>')
              });
              $('#cartes_cnaf').removeClass('d-none')
              $('#btnCnaf').addClass('d-none')
            }
          }
        }
      } else {
        const errorMessageCode = data.errorMessage + ' (' + data.status + ')'
        $('#cafErrorMessage').html(errorMessageCode)
        $('#cafErrorMessage').removeClass('d-none')
      }
    }
  })
})

$('body').on('click', '[id^=adulte_]', function () {
  $('#new_adulte_civilite').val($(this).find('#civilite').data('value'))
  $('#new_adulte_nom').val($(this).find('#nom').data('value'))
  $('#new_adulte_nom').focus()
  $('#new_adulte_prenom').val($(this).find('#prenom').data('value'))
  $('#new_adulte_prenom').focus()
  $('#new_adulte_adresse1').val($(this).find('#rue').data('value'))
  $('#new_adulte_adresse1').focus()
  $('#new_adulte_adresse2').val($(this).find('#complementIdentiteGeo').data('value'))
  $('#new_adulte_adresse2').focus()
  $('#new_adulte_adresse3').val($(this).find('#lieuDit').data('value'))
  $('#new_adulte_adresse3').focus()
  $('#new_adulte_adresse3').blur()
  // Je donne le focus sur les champs pour les reformater et simuler une saisie manuelle

  const routeCodePostal = Routing.generate('trouve_code_postal', { numero: $(this).find('#codePostal').data('value'), nom: $(this).find('#ville').data('value') })
  $.get({
    url: routeCodePostal,
    success: function (codePostalEntity) {
      if (codePostalEntity) {
        $('#new_adulte_adresseCpcle').val(codePostalEntity)
        $('#new_adulte_adresseCpcle').trigger('change')
      }
    }
  })
})

$('body').on('click', '[id^=enfant_]', function () {
  // En création de nouveau dossier
  if ($(this).parent().data('type') === undefined) {
    $('#new_enfant_sexe').val($(this).find('#sexe').data('value'))
    let dateDeNaissance = $(this).find('#dateDeNaissance').data('value').toString()
    $('#new_enfant_dateNaissance_day').val(parseInt(dateDeNaissance.substring(0, 2)))
    $('#new_enfant_dateNaissance_month').val(parseInt(dateDeNaissance.substring(2, 4)))
    $('#new_enfant_dateNaissance_year').val(parseInt(dateDeNaissance.substring(4)))
    $('#new_enfant_nom').val($(this).find('#nom').data('value'))
    $('#new_enfant_nom').focus()
    $('#new_enfant_prenom').val($(this).find('#prenom').data('value'))
    $('#new_enfant_prenom').focus()
    $('#new_enfant_prenom').blur()
    // En ajout d'enfant dans un dossier existant
  } else if ($(this).parent().data('type') === 'enfant') {
    $('#personne_enfant_nom').val($(this).find('#nom').data('value'))
    $('#personne_enfant_nom').focus()
    $('#personne_enfant_prenom').val($(this).find('#prenom').data('value'))
    $('#personne_enfant_prenom').focus()
    $('#personne_enfant_prenom').blur()
    $('#personne_enfant_date_naissance').val($(this).find('#dateDeNaissance').data('value'))
    $('#personne_enfant_enfant_sexe').val($(this).find('#sexe').data('value'))
  }
})

$('body').on('change', '#personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient1, #personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient2', function () {
  if ($(this).is(':checked')) {
    if ($(this).attr('id') === 'personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient1') {
      $('#personne_adulte_adulte_quotientAdulte_quotient1').val(99999)
    } else if ($(this).attr('id') === 'personne_adulte_adulte_quotientAdulte_nePasCommuniquerQuotient2') {
      $('#personne_adulte_adulte_quotientAdulte_quotient2').val(99999)
    }
  }
})

$('body').on('change', '#creche_referent_email', function () {
  const email = $(this)
  let slug = null
  if ($('#only_select_structure_slug').length > 0 && $('#only_select_structure_slug').find(':selected').val() !== '') {
    slug = $('#only_select_structure_slug').val()
  } else if (tools.getCurrentStructureSlug() !== '') {
    slug = tools.getCurrentStructureSlug()
  }
  if (slug) {
    // On va rechercher si ce mail existe pour un adulte appartenant à une famille
    // Si c'est le cas on va le bloquer pour ne pas qu'il saisisse toutes les informations pour rien
    const route = Routing.generate('app_pre_inscriptions_creche_verifie_email', { st_slug: slug })
    $.post({
      url: route,
      data: { email: email.val() },
      success: function (data) {
        if (data['existe']) {
          email.val('')
          $('#modalEmailExistant').modal('toggle')
        } else {
          // On va rechercher si ce mail existe pour un adulte faisant une demande de pré-inscription multi-accueil
          // Si c'est le cas on va récupérer l'étape afin de lui permettre de reprendre sa saisie si celle-ci n'est pas complète
          const routeCreche = Routing.generate('app_pre_inscriptions_creche_trouve_etape', { st_slug: slug })
          $.post({
            url: routeCreche,
            data: { email: email.val(), suivant: true, structure: $('#only_select_structure_slug').val(), st_slug: slug },
            success: function (reponse) {
              if (reponse['valide']) {
                $('#body').data('structure', $('#only_select_structure_slug').val())
                // Si l'on trouve une étape alors on redirige vers l'étape correspondante
                $('#contenu_evolutif').html(reponse.content)
                if (reponse.formulaireSuivant !== null) {
                  $('#suiteInscription').data('formulaire-suivant', reponse.formulaireSuivant)
                  $('#suiteInscription').data('route-suivante', reponse.routeSuivante)
                  $('#suiteInscription').data('numero-demande', reponse.numeroDemande)
                  $('#precedentInscription').data('numero-demande', reponse.numeroDemande)
                  $('#suiteInscription').data('etape', reponse.etape)
                  $('#precedentInscription').data('etape', reponse.etape)
                  $('#precedentInscription').show()
                  genereFilAriane(reponse.etape)
                  if (reponse.etape === 5) {
                    $('#suiteInscription').html('Terminer')
                  } else if (reponse.etape === 6) {
                    $('#suiteInscription').hide()
                  }
                }
                if (reponse.nom) {
                  $('#form_creche_adulte1 #creche_adulte_nom').val(reponse.nom)
                }
                if (reponse.prenom) {
                  $('#form_creche_adulte1 #creche_adulte_prenom').val(reponse.prenom)
                }
                if (reponse.telephone) {
                  $('#form_creche_adulte1 #creche_adulte_telPortable').val(reponse.telephone)
                }
                if (reponse.messagePreInscriptionCreche) {
                  $('#messagePreInscriptionCreche').html(reponse.messagePreInscriptionCreche)
                }
              }
            }
          })
        }
      }
    })
  }
})

$('body').on('click', '[id^=recopierEnfant]', function (event) {
  // On empêche de refermer la boîte si celle-ci est ouverte
  const ariaExpanded = ($(this).parent().attr('aria-expanded') === 'true')
  if (ariaExpanded) {
    event.stopPropagation()
  }
  // On va aller recopier tous les champs de l'enfant précédent sauf le prénom
  const indice = parseInt($(this).attr('id').slice(-1))
  const indicePrecedent = indice - 1
  $('#collapseenfants' + indicePrecedent + ' .input-group.date-group .form-control').each(function (index) {
    if ($(this) && !$(this).hasClass('no-copy')) {
      $('#collapseenfants' + indice + ' #' + $(this).attr('id')).val($(this).val())
    }
  })
  // On va également recopier les horaires
  if ($('#horaires' + indicePrecedent + ' #semaine_1').length || $('#horaires' + indicePrecedent + ' #semaine_54').length || $('#horaires' + indicePrecedent + ' #semaine_55').length) {
    const parentCarteHoraires = $('#horaires' + indicePrecedent).find('[id^=semaine_]').parent().clone()
    $('#horaires' + indice + ' #' + parentCarteHoraires.attr('id')).replaceWith(parentCarteHoraires)
    const typeSemaineCopie = $('#horaires' + indicePrecedent + ' #typeSemaine').clone()
    $('#horaires' + indice + ' #typeSemaine').replaceWith(typeSemaineCopie)
    const joursDisponiblesCopie = $('#horaires' + indicePrecedent + ' #joursDisponibles').clone()
    $('#horaires' + indice + ' #joursDisponibles').replaceWith(joursDisponiblesCopie)
    if ($('#horaires' + indicePrecedent + ' .masquer-horaires').hasClass('d-none')) {
      $('#horaires' + indice + ' .masquer-horaires').removeClass('d-flex').addClass('d-none')
    }
    $('#horaires' + indice + ' #enregistreHoraires').removeClass().addClass($('#horaires' + indicePrecedent + ' #enregistreHoraires').attr('class'))
  }
})

$('body').on('click', '#enregistreHoraires', function () {
  const horaireId = $(this).data('id')
  const cible = $('#' + horaireId)
  if (verifieDonnees(horaireId)) {
    const typeSemaineId = parseInt(cible.find('#creche_horaires_semaine').val())
    let couleur = 'bg-family'
    if (typeSemaineId === 54) {
      couleur = 'bg-facture'
    } else if (typeSemaineId === 55) {
      couleur = 'bg-inscription'
    }
    const typeSemaine = cible.find('#creche_horaires_semaine  option:selected').html()
    const arrayHorairesSaisis = { lundi: [], mardi: [], mercredi: [], jeudi: [], vendredi: [], samedi: [], dimanche: [] }
    // Nous allons récupérer tous les horaires saisis afin de les condenser dans une carte
    cible.find('[id^=creche_horaires_heurePeriode]').each(function () {
      if ($(this).val()) {
        arrayHorairesSaisis[$(this).data('jour')].push($(this).val())
      }
    })
    let carte =
      '<div id="semaine_' + typeSemaineId + '" class="card px-0 mx-1" style="min-width: 300px">' +
      '<div id="horairesJSON" class="d-none">' + JSON.stringify(arrayHorairesSaisis) + '</div>' +
      '<h4 class="h5 card-header d-flex justify-content-between ' + couleur + '">' +
      '<div>' +
      '<i class="fa fa-calendar" aria-hidden="true"></i> ' + typeSemaine +
      '</div>' +
      '<div>' +
      '<a href="#creche_enfant_motivations" id="modifieHorairesCreche" data-semaine="' + typeSemaineId + '" class="ml-2" data-toggle="tooltip" title="Modifier ces horaires"><i class="fa fa-pencil text-dark" aria-hidden="true"></i></a> ' +
      '<a href="#creche_enfant_motivations" id="supprimeHorairesCreche" data-semaine="' + typeSemaineId + '" data-toggle="tooltip" title="Supprimer ces horaires"><i class="fa fa-trash text-dark" aria-hidden="true"></i></a> ' +
      '</div>' +
      '</h4>' +
      '<div id="cardBodySemaine" class="card-body bg-white">' +
      '<div id="horaires" class="d-flex flex-column">'
    for (const property in arrayHorairesSaisis) {
      if (arrayHorairesSaisis[property].length > 0) {
        carte +=
          '<div class="d-flex">' +
          '<div style="min-width: 75px;">' + property + ' : </div>'
        let compteur = 1
        let horaireJour = ''
        for (let i = 0; i < 4; i++) {
          if (arrayHorairesSaisis[property][i]) {
            horaireJour += arrayHorairesSaisis[property][i]
            if (compteur === 1 || compteur === 3) {
              horaireJour += ' - '
            } else if (compteur === 2) {
              compteur = 0
              if (i === 1 && arrayHorairesSaisis[property][i + 1]) {
                horaireJour += ', '
              }
            }
          }
          compteur++
        }
        carte +=
          '<div id="' + property + '" class="d-flex justify-content-center w-100 mr-1">' + horaireJour + '</div>' +
          '</div>'
      }
    }
    carte +=
      '</div>' +
      '</div>' +
      '</div>'

    cible.find('#carteHoraires').append(carte)
    // On va masquer les options du select qui ne peuvent plus être utilisées et la saisie des horaires
    cacheInformationsHoraires(cible, typeSemaineId)
    // On va vider tous les champs de saisie pour faire potentiellement une nouvelle saisie
    cible.find('[id^=creche_horaires_heurePeriode]').each(function () {
      $(this).val('')
    })
    const url = location.href
    location.href = '#creche_enfant_motivations'
    history.replaceState(null, null, url)
  }
})

function afficheInformationsHoraires (cible, typeSemaineId) {
  modificationInformationsHoraires('affiche', cible, typeSemaineId)
}

function cacheInformationsHoraires (cible, typeSemaineId) {
  modificationInformationsHoraires('cache', cible, typeSemaineId)
}

function modificationInformationsHoraires (type, cible, typeSemaineId) {
  const selectTypeSemaine = cible.find('#creche_horaires_semaine option')
  if (typeSemaineId === 1) {
    modifieHoraires(type, cible)
  } else if (typeSemaineId === 54) {
    if (trouveAutreSemaine(cible, 55)) {
      modifieHoraires(type, cible, 54)
    } else {
      modifieOptionsSemaine(type, selectTypeSemaine, 55)
    }
  } else if (typeSemaineId === 55) {
    if (trouveAutreSemaine(cible, 54)) {
      modifieHoraires(type, cible, 55)
    } else {
      modifieOptionsSemaine(type, selectTypeSemaine, 54)
    }
  }
}

function trouveAutreSemaine (cible, numeroSemaine) {
  const semaineId = '#semaine_' + numeroSemaine
  return cible.find(semaineId).length
}

function modifieOptionsSemaine (type, selectTypeSemaine, semaine) {
  if (type === 'affiche') {
    // Il faut prendre la paire quand c'est impaire et inversement
    if (semaine === 54) {
      semaine = 55
    } else if (semaine === 55) {
      semaine = 54
    }
  }
  selectTypeSemaine.each(function () {
    if (type === 'affiche') {
      $(this).removeAttr('disabled')
      if (parseInt($(this).val()) !== semaine) {
        $(this).removeAttr('selected')
      } else {
        $(this).attr('selected', 'selected')
        $(this).parent().val(semaine)
      }
    } else if (type === 'cache') {
      if ($(this).val() !== '' && parseInt($(this).val()) !== semaine) {
        $(this).attr('disabled', 'disabled')
      } else if ($(this).val() === '') {
        $(this).parent().val('')
      }
    }
  })
}

function modifieHoraires (type, cible, semaine = null) {
  if (type === 'affiche') {
    // On affiche le sélecteur de type de semaine
    cible.find('#typeSemaine').removeClass('d-none').addClass('d-flex')
    cible.find('#joursDisponibles').removeClass('d-none').addClass('d-flex')
    cible.find('#typeSemaine option').each(function () {
      if (parseInt($(this).val()) === semaine) {
        $(this).removeAttr('disabled')
        $(this).attr('selected', 'selected')
        $(this).parent().val(semaine)
      } else {
        if ($(this).val() !== '' && semaine !== null) {
          $(this).attr('disabled', 'disabled')
        }
        $(this).removeAttr('selected')
      }
    })
    // On affiche la sélection d'horaires
    cible.find('.masquer-horaires').removeClass('d-none').addClass('d-flex')
    cible.find('.masquer-horaires').children().each(function () {
      const ligne = $(this)
      ligne.find('[id^=creche_horaires_heurePeriode').each(function () {
        if ($(this).val() !== '') {
          ligne.removeClass('d-none').addClass('d-flex')
          cible.find('#afficheJour' + $(this).data('jour')).prop('checked', true)
        }
      })
    })
    // On affiche le bouton d'enregistrement
    cible.find('#enregistreHoraires').removeClass('d-none')
  } else if (type === 'cache') {
    // On masque le sélecteur de type de semaine
    cible.find('#typeSemaine').removeClass('d-flex').addClass('d-none')
    cible.find('#joursDisponibles').removeClass('d-flex').addClass('d-none')
    // On masque la sélection d'horaires
    cible.find('.masquer-horaires').each(function () {
      $(this).removeClass('d-flex').addClass('d-none')
    })
    // On masque le bouton d'enregistrement
    cible.find('#enregistreHoraires').addClass('d-none')
  }
}

function verifieDonnees (cible) {
  const cibleErreurs = $('#' + cible)
  const semaine = verifieSemaine(cible)
  const horaires = verifieHoraires(cible)
  const valide = semaine['valide'] && horaires['valide']
  const listeErreurs = []
  if (valide) {
    cibleErreurs.find('#erreurHoraires').html('')
    cibleErreurs.find('#erreurHoraires').addClass('d-none')
  } else {
    if (!semaine['valide']) {
      listeErreurs.push(semaine['erreurs'])
    }
    if (!horaires['valide']) {
      listeErreurs.push(horaires['erreurs'])
    }
    let chaineErreurs = ''
    listeErreurs.forEach(element => {
      chaineErreurs += element.join('<br />')
    })
    cibleErreurs.find('#erreurHoraires').html(chaineErreurs)
    cibleErreurs.find('#erreurHoraires').removeClass('d-none')
  }

  return valide
}

function verifieSemaine (cible) {
  const cibleHorairesSemaine = $('#' + cible + ' #creche_horaires_semaine')
  const valide = cibleHorairesSemaine.find('option:selected').val() !== ''
  const erreurs = []
  cibleHorairesSemaine.css('background-color', 'white')
  if (!valide) {
    erreurs.push('Vous devez sélectionner un type de semaine.<br />')
    cibleHorairesSemaine.css('background-color', '#fbe2e0')
  }
  return { 'valide': valide, 'erreurs': erreurs }
}

function verifieHoraires (cible) {
  const cibleHoraires = $('#' + cible)
  const erreurHeureDebut = "L'heure de début est obligatoire."
  const erreurHeureFin = "L'heure de fin est obligatoire."
  const erreurHeuresIncoherentes = "L'heure de fin doit être supérieure à l'heure de début."
  const erreurVide = 'Vous devez saisir au moins un horaire.'
  const listeErreurs = []
  let valide = true
  let auMoinsUneLigne = false
  cibleHoraires.find('[id^=creche_horaires_heurePeriode]').each(function () {
    $(this).css('background-color', 'white')
  })
  for (let i = 1; i < 3; i++) {
    cibleHoraires.find('[id^=creche_horaires_heurePeriode' + i + ']').each(function () {
      if ($(this).val()) {
        if ($(this).attr('id').indexOf('Debut') !== -1) {
          const erreurIdDebut = cibleHoraires.find('#creche_horaires_heurePeriode' + i + 'Debut' + $(this).attr('id').replace('creche_horaires_heurePeriode' + i + 'Debut', ''))
          const erreurIdFin = cibleHoraires.find('#creche_horaires_heurePeriode' + i + 'Fin' + $(this).attr('id').replace('creche_horaires_heurePeriode' + i + 'Debut', ''))
          if (!erreurIdFin.val()) {
            if (!listeErreurs.includes(erreurHeureFin)) {
              listeErreurs.push(erreurHeureFin)
            }
            erreurIdFin.css('background-color', '#fbe2e0')
            valide = false
          } else {
            auMoinsUneLigne = true
            if (erreurIdDebut.val() >= erreurIdFin.val()) {
              valide = false
              if (!listeErreurs.includes(erreurHeuresIncoherentes)) {
                listeErreurs.push(erreurHeuresIncoherentes)
              }
              erreurIdFin.css('background-color', '#fbe2e0')
              $(this).css('background-color', '#fbe2e0')
            }
          }
        } else if ($(this).attr('id').indexOf('Fin') !== -1) {
          const erreurIdDebut = cibleHoraires.find('#creche_horaires_heurePeriode' + i + 'Debut' + $(this).attr('id').replace('creche_horaires_heurePeriode' + i + 'Fin', ''))
          const erreurIdFin = cibleHoraires.find('#creche_horaires_heurePeriode' + i + 'Fin' + $(this).attr('id').replace('creche_horaires_heurePeriode' + i + 'Fin', ''))
          if (!erreurIdDebut.val()) {
            if (!listeErreurs.includes(erreurHeureDebut)) {
              listeErreurs.push(erreurHeureDebut)
            }
            erreurIdDebut.css('background-color', '#fbe2e0')
            valide = false
          } else {
            auMoinsUneLigne = true
            if (erreurIdDebut.val() >= erreurIdFin.val()) {
              valide = false
              if (!listeErreurs.includes(erreurHeuresIncoherentes)) {
                listeErreurs.push(erreurHeuresIncoherentes)
              }
              erreurIdDebut.css('background-color', '#fbe2e0')
              $(this).css('background-color', '#fbe2e0')
            }
          }
        }
      }
    })
  }
  if (!auMoinsUneLigne) {
    valide = false
    listeErreurs.push(erreurVide)
  }
  return { 'valide': valide, 'erreurs': listeErreurs }
}

$('body').on('click', '#modifieHorairesCreche', function () {
  const horaireId = $(this).closest('.parent-horaires').attr('id')
  const semaine = parseInt($(this).data('semaine'))
  const horaires = $('#' + horaireId + ' #semaine_' + semaine + ' #horairesJSON').html()
  if (horaires) {
    const horairesJSON = JSON.parse(horaires)
    if (typeof horairesJSON === 'object' && horairesJSON != null) {
      // On commence par remttre les valeurs dans les champs de saisie
      for (const [key, value] of Object.entries(horairesJSON)) {
        if (value.length > 0) {
          for (let i = 0; i < 4; i++) {
            if (value[i]) {
              let periode = 1
              if (i > 1) {
                periode = 2
              }
              let debutOuFin = 'Debut'
              if ((i + 1) % 2 === 0) {
                debutOuFin = 'Fin'
              }
              $('#' + horaireId + ' #creche_horaires_heurePeriode' + periode + debutOuFin + key).val(value[i])
            }
          }
        }
      }
      // On va afficher la saisie des horaires si elle a été masquée
      afficheInformationsHoraires($('#' + horaireId), semaine)
      // On enlève la carte des horaires
      $('#' + horaireId + ' #semaine_' + semaine).remove()
    }
  }
})

$('body').on('click', '#supprimeHorairesCreche', function () {
  const semaine = parseInt($(this).data('semaine'))
  const horaireId = $(this).closest('.parent-horaires').attr('id')
  // On va afficher la saisie des horaires si elle a été masquée
  afficheInformationsHoraires($('#' + horaireId), semaine)
  // On enlève la carte des horaires
  $('#' + horaireId + ' #semaine_' + semaine).remove()
})

function correspondanceEtapeFilAriane (etape) {
  const nbEtapes = parseInt($('.progress-wizard-step').length)
  if (etape > nbEtapes) {
    etape = etape - (etape - nbEtapes)
    $('.progress-wizard-step:nth-child(' + etape + ')').removeClass('complete').addClass('active')
    if (etape < nbEtapes) {
      while (etape <= nbEtapes) {
        etape++
        $('.progress-wizard-step:nth-child(' + etape + ')').removeClass('complete')
      }
    }
  }
}

function ajouteFilAriane () {
  const etapeFil = parseInt($('.progress-wizard-step.active').data('step')) + 1
  $('.progress-wizard-step.active').removeClass('active').addClass('complete')
  $('.progress-wizard-step:nth-child(' + etapeFil + ')').removeClass('incomplete').addClass('active')
}

function retireFilAriane (etape) {
  correspondanceEtapeFilAriane(etape)
  const etapeFil = parseInt($('.progress-wizard-step.active').data('step')) - 1
  $('.progress-wizard-step.active').removeClass('active').addClass('incomplete')
  $('.progress-wizard-step:nth-child(' + etapeFil + ')').removeClass('complete').addClass('active')
}

function genereFilAriane (etapeFil) {
  $('.progress-wizard-step').each(function () {
    if (parseInt($(this).data('step')) < etapeFil) {
      $(this).removeClass('active').removeClass('incomplete').addClass('complete')
    } else if (parseInt($(this).data('step')) === etapeFil) {
      $(this).removeClass('incomplete').addClass('active')
    }
  })
  correspondanceEtapeFilAriane(etapeFil)
}

$('body').on('click', '#precedentInscription', function (e) {
  let slug = null
  if ($('#only_select_structure_structure').length > 0 && $('#only_select_structure_structure').find(':selected').val() !== '') {
    slug = $('#only_select_structure_structure').val()
  } else if (tools.getCurrentStructureSlug() !== '') {
    slug = tools.getCurrentStructureSlug()
  } else if ($('#body').data('structure') !== '') {
    slug = $('#body').data('structure')
  }
  $.post({
    url: Routing.generate('app_pre_inscriptions_creche_trouve_etape', { st_slug: slug }),
    data: { etape: $(this).data('etape'), ajax: true, demande: $(this).data('numero-demande'), suivant: false, st_slug: slug },
    success: function (reponse) {
      $('#contenu_evolutif').html(reponse.content)
      $('#suiteInscription').data('formulaire-suivant', reponse.formulaireSuivant)
      $('#suiteInscription').data('route-suivante', reponse.routeSuivante)
      $('#suiteInscription').data('numero-demande', reponse.numeroDemande)
      $('#precedentInscription').data('numero-demande', reponse.numeroDemande)
      $('#suiteInscription').data('etape', reponse.etape)
      $('#precedentInscription').data('etape', reponse.etape)
      retireFilAriane(reponse.etape)
      // TODO à revoir
      if (reponse.etape === 2 && reponse.connecte) {
        $('#precedentInscription').hide()
      }
      $('#suiteInscription').show()
      if (reponse.etape === 1) {
        $('#precedentInscription').hide()
      } else if (reponse.etape < 5) {
        $('#suiteInscription').html('Suivant <i class="fa fa-caret-right"></i>')
      } else if (reponse.etape === 5) {
        $('#suiteInscription').html('Terminer')
      } else if (reponse.etape === 6) {
        $('#suiteInscription').hide()
      }
      $('.select-filter-global, .select-filter-multiple').select2({
        language: 'fr'
      })
    }
  })
})

function erreurHoraires () {
  let erreur = false
  // Je le fais en JS natif car en Jquery pas moyen de récupérer correctement
  // Je vide à chaque boucle pour être tranquille et n'avoir que ceux qui posent réellement problème
  const zonesMessageErreur = document.querySelectorAll('#erreurHoraires')
  zonesMessageErreur.forEach(function (element) {
    element.classList.add('d-none')
    element.innerHTML = ''
  })

  const casesHoraires = $('[id^=creche_horaires_heurePeriode]')
  let erreurMessage = ''
  // Si nous avons au moins une fois la case alors il faut vérifer si nous avons des données dedans, si c'est le cas alors bloquer l'enregistrement
  // Afficher le message d'erreur comme quoi ce n'est pas enregistré
  if (casesHoraires.length > 0) {
    casesHoraires.each(function () {
      if ($(this).val()) {
        erreur = true
        erreurMessage = 'Vous avez des horaires non enregistrés, veuillez les enregistrer ou les supprimer afin de pouvoir passer à l\'étape suivante.'
        $(this).closest('.parent-horaires').find('#erreurHoraires').html(erreurMessage)
        $(this).closest('.parent-horaires').find('#erreurHoraires').removeClass('d-none')
        const indice = parseInt($(this).closest('.parent-horaires').attr('id').slice(-1))
        if ($('#headingenfants' + indice).attr('aria-expanded') === 'false') {
          $('#headingenfants' + indice).click()
        }
      }
    })
  }

  const casesJours = $('[id^=afficheJour]')
  if (casesJours.length > 0 && $('#horairesJSON').length === 0) {
    let checked = false
    casesJours.each(function () {
      if ($(this).checked) {
        checked = true
      }
    })
    if (!checked) {
      erreur = true
      const accordeonEnfantVisible = $('[id^=collapseenfants]:visible')
      let sautDeLigne = ''
      if (erreurMessage !== '') {
        sautDeLigne = '<br>'
      }
      erreurMessage = erreurMessage + sautDeLigne + 'Vous devez obligatoirement renseigner les jours et les horaires de pré-inscription.'
      accordeonEnfantVisible.find('#erreurHoraires').html(erreurMessage)
      accordeonEnfantVisible.find('#erreurHoraires').removeClass('d-none')
    }
  }

  return erreur
}

$('body').on('click', '#suiteInscription', function (e) {
  e.preventDefault()

  // Si des horaires ne sont pas enregistrés alors on empêche de passer à l'étape suivante et on affiche le message d'erreur.
  if (erreurHoraires()) {
    return
  }

  const routeSuivante = $(this).data('route-suivante')
  const etape = parseInt($(this).data('etape'))
  const routeName = 'app_pre_inscriptions_creche_' + routeSuivante
  let slug = null
  if ($('#only_select_structure_slug').length > 0 && $('#only_select_structure_slug').find(':selected').val() !== '') {
    slug = $('#only_select_structure_slug').val()
  } else if (tools.getCurrentStructureSlug() !== '') {
    slug = tools.getCurrentStructureSlug()
  } else if ($('#body').data('structure') !== '') {
    slug = $('#body').data('structure')
  }

  let valide = true
  let reponse = null
  const arraySousContenu = []
  for (var i = 0; i < document.forms.length; i++) {
    const indiceSuivant = i + 1
    const horairesSaisis = {}
    $('#horaires' + indiceSuivant + ' [id^=semaine]').each(function () {
      const numeroSemaine = parseInt($(this).attr('id').replace('semaine_', ''))
      if ($(this).find('#horairesJSON').html()) {
        horairesSaisis[numeroSemaine] = JSON.parse($(this).find('#horairesJSON').html())
      }
    })
    const route = Routing.generate(routeName, {
      st_slug: slug,
      numeroDemande: parseInt($(this).data('numero-demande')),
      indice: indiceSuivant,
      etape: etape,
      horaires: JSON.stringify(horairesSaisis),
      slug: slug
    })
    const formData = new FormData(document.getElementById(document.forms[i].id))
    let vide = false
    if (i > 0 && document.forms[i].id !== 'form_creche_referent') {
      vide = true
      const champsRequis = document.getElementById(document.forms[i].id).elements
      for (var champ = 0; champ < champsRequis.length; ++champ) {
        var item = champsRequis[champ]
        // on ignore le token qui a toujours une valeur
        if (((item.type !== 'checkbox' && item.value) || (item.type === 'checkbox' && item.checked)) && item.id.indexOf('__token') === -1) {
          vide = false
          break
        }
      }
    }
    if (!vide) {
      $.post({
        url: route,
        data: formData,
        processData: false,
        contentType: false,
        async: false,
        success: function (response) {
          if (!response.valide) {
            valide = false
            arraySousContenu[indiceSuivant] = $(response.content).find('#sous_contenu_evolutif_' + indiceSuivant)
          }
          if (reponse === null || (reponse !== null && !response.valide) || response.valide) {
            reponse = response
          }
        }
      })
    }
  }
  if (valide) {
    if ($('#body').data('structure') === '') {
      $('#body').data('structure', slug)
    }
    $('#contenu_evolutif').html(reponse.content)
    if (reponse.formulaireSuivant !== null) {
      // On va enregistrer l'étape en BDD car tous les form sont ok
      $.post({
        url: Routing.generate('app_pre_inscriptions_creche_enregistre_etape', { st_slug: slug }),
        data: { demande: reponse.numeroDemande, etape: reponse.etapeActuelle }
      })
      $('#suiteInscription').data('formulaire-suivant', reponse.formulaireSuivant)
      $('#suiteInscription').data('route-suivante', reponse.routeSuivante)
      $('#suiteInscription').data('numero-demande', reponse.numeroDemande)
      $('#precedentInscription').data('numero-demande', reponse.numeroDemande)
      $('#suiteInscription').data('etape', reponse.etape)
      $('#precedentInscription').data('etape', reponse.etape)
      $('#precedentInscription').show()
      ajouteFilAriane()
      // TODO à revoir
      if (reponse.etape === 5) {
        $('#suiteInscription').html('Terminer')
      } else if (reponse.etape === 6) {
        $('#suiteInscription').hide()
      }
    }
    if (reponse.nom) {
      $('#form_creche_adulte1 #creche_adulte_nom').val(reponse.nom)
    }
    if (reponse.prenom) {
      $('#form_creche_adulte1 #creche_adulte_prenom').val(reponse.prenom)
    }
    if (reponse.telephone) {
      $('#form_creche_adulte1 #creche_adulte_telPortable').val(reponse.telephone)
    }
    if (reponse.messagePreInscriptionCreche) {
      $('#messagePreInscriptionCreche').html(reponse.messagePreInscriptionCreche)
    }
  } else {
    let continuer = true
    // On va retirer toutes les erreurs qui n'ont plus lieu d'être
    // ça ressemble un peu à de la bidouille ça mais je n'y arrive pas autrement
    $('[id^=sous_contenu_evolutif_]').each(function () {
      let enErreur = false
      arraySousContenu.forEach((sousContenu) => {
        if ($(this).attr('id') === sousContenu.attr('id')) {
          enErreur = true
        }
      })
      if (!enErreur) {
        $(this).find('.is-invalid').removeClass('is-invalid')
        $(this).find('.invalid-feedback.d-block').remove()
        $(this).closest('div.card').find('#erreurFormulaire').addClass('d-none')
      }
    })
    arraySousContenu.forEach((sousContenu, indice) => {
      $('#sous_contenu_evolutif_' + indice).replaceWith(sousContenu)
      // On va donner le focus sur le premier champ en erreur
      if ($('#sous_contenu_evolutif_' + indice).find('form .is-invalid').length > 0) {
        $('#headingfamille' + indice).find('#erreurFormulaire').removeClass('d-none')
        if (continuer) {
          if ($('#headingfamille' + indice).attr('aria-expanded') === 'false') {
            $('#headingfamille' + indice).trigger('click')
          }
          // je mets une tempo pour que l'accordéon se soit bien ouvert
          setTimeout(function () {
            $('#sous_contenu_evolutif_' + indice).find('form .is-invalid').first().trigger('focus')
          }, 200)
          continuer = false
        }
      }
      // On va donner le focus sur le premier champ en erreur
      // On met une croix rouge en entête de boîte si erreur mais repliée
    })
  }
  $('.select-filter-global, .select-filter-multiple').select2({
    language: 'fr'
  })
})

function recopieHeures (element, cible) {
  const courant = element
  const typeHoraire = element.attr('id').replace(element.data('jour'), '')
  const valeur = element.val()
  const classeCourante = element.attr('class')
  cible.find('[id^=' + typeHoraire).each(function () {
    if ($(this) !== courant && cible.find('#afficheJour' + $(this).data('jour')).is(':checked')) {
      $(this).val(valeur)
      $(this).removeClass().addClass(classeCourante)
    }
  })
}

$('body').on('click', '#recopieHoraires:checked', function () {
  // Si l'on coche la case et que l'on a aucun horaire saisi alors on ne fait rien sinon on reporte le 1er horaire trouvé
  // Si l'on décoche on fait quoi? Je ne fais rien pour le moment
  const cible = $('#' + $(this).data('id'))
  cible.find('[id^=creche_horaires_heurePeriode').each(function () {
    if ($(this).val()) {
      recopieHeures($(this), cible)
    }
  })
})

$('body').on('blur', '[id^=creche_horaires_heurePeriode]', function () {
  const saisie = moment($(this).val(), 'HH:mm')
  const min = moment($(this).data('min'), 'HH:mm')
  const max = moment($(this).data('max'), 'HH:mm')
  const cible = $(this).closest('.parent-horaires')
  if (saisie < min || saisie > max) {
    $(this).addClass('alert alert-danger')
    cible.find('#enregistreHoraires').attr('disabled', 'disabled')
    cible.find('#enregistreHoraires').html('<i class="fa fa-ban"></i> Horaires incohérents')
  } else {
    $(this).removeClass('alert alert-danger')
    let valide = true
    cible.find('[id^=creche_horaires_heurePeriode]').each(function () {
      if ($(this).hasClass('alert-danger')) {
        valide = false
      }
    })
    if (valide) {
      cible.find('#enregistreHoraires').removeAttr('disabled')
      cible.find('#enregistreHoraires').html('<i class="fa fa-save"></i> Enregistrer ces horaires')
    }
  }
  if ($(this).val() !== '' && $('#recopieHoraires:checked').length > 0) {
    recopieHeures($(this), cible)
  }
})

$('body').on('change', '[id^=afficheJour]', function () {
  const cible = $('#' + $(this).data('cible'))
  if ($(this).is(':checked')) {
    cible.find('#' + $(this).data('jour')).removeClass('d-none').addClass('d-flex')
  } else {
    cible.find('#' + $(this).data('jour')).addClass('d-none').removeClass('d-flex')
  }
  // Il faut aussi vérifier qu'on ait au moins une case cochée pour affiche le tableau des jours
  // Ou le masquer si l'on en a aucune
  let visible = false
  cible.find('[id^=afficheJour]').each(function () {
    if ($(this).is(':checked')) {
      visible = true
    }
  })
  if (visible) {
    cible.find('#tableauJours').removeClass('d-none').addClass('d-flex')
  } else {
    cible.find('#tableauJours').addClass('d-none').removeClass('d-flex')
  }
})

$('body').on('change', '#only_select_structure_slug', function () {
  $('body').data('structure', $(this).val())
  // Lorsque l'on change de structure le fil d'ariane peut changer alors il faut recharger le visuel
  const slug = $(this).val()
  if (slug !== '') {
    $('#suiteInscription').removeAttr('disabled')
    // On va remplacer le fil existant par le nouveau
    $.post({
      url: Routing.generate('app_pre_inscriptions_creche_fil_ariane', { st_slug: slug }),
      success: function (reponse) {
        $('#fil_ariane').replaceWith(reponse.filAriane)
      }
    })
  } else {
    $('#suiteInscription').attr('disabled', 'disabled')
  }
})
