$(document).ready(function () {
  // * Ajouter une PJ
  $('body').on('click', '.ajoutePieceJointe', function () {
    const current = $(this).closest('.piecesJointes')
    const next = $('.piecesJointes.d-none').first()
    $('.ajoutePieceJointe').each(function () {
      $(this).addClass('d-none')
    })
    current.find('.ajoutePieceJointe').addClass('d-none')
    next.removeClass('d-none')
    const nextAdd = $('.piecesJointes').not('.d-none').last().find('.ajoutePieceJointe')
    nextAdd.removeClass('d-none')
    // * S'il y a plus d'une ligne on remplace le libellé par en ajoutant des "s"
    if ($('.piecesJointes').not('.d-none').length > 1) {
      $('#labelPieceJointe').html('Pièces jointes')
    }
    // * S'il n'y a aucune ligne cachée alors on masque tous les boutons d'ajout
    if ($('.piecesJointes.d-none').length === 0) {
      $('.ajoutePieceJointe').each(function () {
        $(this).addClass('d-none')
      })
    }
  })

  // * Supprimer la PJ et la ligne
  $('body').on('click', '.supprimePieceJointe', function () {
    const current = $(this).closest('.piecesJointes')
    // * On nettoie le contenu du champ pour ne pas l'envoyer dans le form même s'il est masqué
    current.find('input').val('')
    current.find('label').html('')
    current.find('img').remove()
    let lineHasAdd = false
    const linesHidden = $('.piecesJointes.d-none').length
    if (current.find('.ajoutePieceJointe').not('.d-none')) {
      lineHasAdd = true
    }
    if (linesHidden < 4) {
      current.removeClass('d-flex').addClass('d-none')
    }
    if ($('.piecesJointes.d-none').length >= 4) {
      $('#labelPieceJointe').html('Pièce jointe')
    }
    if ($('.piecesJointes.d-none').length >= 4) {
      $('.ajoutePieceJointe:first').removeClass('d-none')
    }
    if (lineHasAdd || linesHidden === 0) {
      $('.piecesJointes').not('.d-none').last().find('.ajoutePieceJointe').removeClass('d-none')
    }
  })
})
