import helpers from './helpers'
import moment from 'moment'

function isAllowInFilter ($card) {
  const selectPeriode = $('#periodeselect').val()
  const checkrepas = $('#repascheck').is(':checked')
  const selectWeek = $('#weekselect').val()
  const selectYear = parseInt($('#yearSelect').val())

  const heureDebut = $card.data('heure-debut')
  const heureFin = $card.data('heure-fin')
  const avecrepas = $card.data('avecrepas') === true

  // Si c'est faux alors je quitte, si c'est vrai alors je continue les autres tests
  if (selectYear > 0) {
    if (anneeEstDansPeriode($card, selectYear) === false) {
      return false
    }
  }

  // Si c'est faux alors je quitte, si c'est vrai alors je continue les autres tests
  if (selectWeek > 0) {
    if (semaineEstDansPeriode($card) === false) {
      return false
    }
  }

  if (avecrepas === checkrepas || checkrepas === false) {
    if (heureDebut && heureFin) {
      const arraytimeDebut = heureDebut.split('h')
      const arraytimeFin = heureFin.split('h')
      const minDebut = parseInt(arraytimeDebut[0]) * 60 + parseInt(arraytimeDebut[1])
      const minFin = parseInt(arraytimeFin[0]) * 60 + parseInt(arraytimeFin[1])

      const dumatin = minDebut > (5 * 60) && minDebut < (10 * 60) && minFin < (14 * 60)
      const delaprem = minDebut > (10 * 60) && minFin > (14 * 60) && minFin < (22 * 60)
      const delajournee = minDebut > (5 * 60) && minDebut < (10 * 60) && minFin > (14 * 60) && minFin < (22 * 60)

      if (selectPeriode === 'all') {
        return true
      } else if (selectPeriode === 'am') {
        return dumatin
      } else if (selectPeriode === 'pm') {
        return delaprem
      } else if (selectPeriode === 'journee') {
        return delajournee
      } else if (selectPeriode === 'divers') {
        if (!dumatin && !delaprem && !delajournee) {
          return true
        } else {
          return false
        }
      }
    } else if (selectPeriode === 'divers') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

function loopInButton () {
  $('.btn-filter-regroupement').each(function (_, btn) {
    const regroupementId = $(btn).data('regroupement')
    const $card = $('body .card-regroupement-' + regroupementId)
    let isAvailableResa = false
    if ($(btn).hasClass('active')) {
      $card.each(function (_, elm) {
        if (isAllowInFilter($(elm)) === true) {
          isAvailableResa = true
          $(elm).show('slow')
        } else {
          $(elm).hide()
        }
      })
    } else {
      $card.each(function (_, elm) {
        if (isAllowInFilter($(elm)) === true) {
          isAvailableResa = true
        }
        $(elm).hide()
      })
    }
    // J'ai enlevé le slow car ça faisait un affichage bizarre pour les regroupements
    if (isAvailableResa) { $(btn).show() } else { $(btn).hide() }
  })
}

function triDate () {
  var result = $('.card-activite').sort(function (a, b) {
    const date1 = $(a).data('activite-debut')
    const date2 = $(b).data('activite-debut')
    return moment(date1, 'DD/MM/YYYY').toDate() - moment(date2, 'DD/MM/YYYY').toDate()
  })
  $('#inscription-list').html(result)
}

function triNom () {
  var result = $('.card-activite').sort(function (a, b) {
    return $(a).data('nomactivite').localeCompare($(b).data('nomactivite'))
  })
  $('#inscription-list').html(result)
}

function semaineEstDansPeriode ($card) {
  const dateActiviteDebut = moment($card.data('activite-debut'), 'DD/MM/YYYY').toDate()
  const dateActiviteFin = moment($card.data('activite-fin'), 'DD/MM/YYYY').toDate()
  const dateSemaineDebut = moment($('#weekselect').find(':selected').data('start'), 'DD/MM/YYYY').toDate()
  const dateSemaineFin = moment($('#weekselect').find(':selected').data('end'), 'DD/MM/YYYY').toDate()
  if (
    (dateSemaineDebut <= dateActiviteDebut && dateActiviteDebut <= dateSemaineFin) ||
    (dateSemaineDebut <= dateActiviteFin && dateActiviteFin <= dateSemaineFin)
  ) {
    return true
  } else {
    return false
  }
}

function anneeEstDansPeriode ($card, selectYear) {
  const anneeScolaireCourante = 1
  const anneeScolaireAVenir = 2
  const dateActiviteDebut = moment($card.data('activite-debut'), 'DD/MM/YYYY').toDate()
  const dateActiviteFin = moment($card.data('activite-fin'), 'DD/MM/YYYY').toDate()
  const dateBasculePeriode = moment($('#yearSelect').data('date-bascule-periode'), 'DD/MM/YYYY').toDate()

  if (anneeScolaireCourante === selectYear) {
    if (dateActiviteDebut >= dateBasculePeriode) {
      return false
    } else {
      return true
    }
  }

  if (anneeScolaireAVenir === selectYear) {
    if (dateActiviteFin <= dateBasculePeriode) {
      return false
    } else {
      return true
    }
  }
}

(function ($) {
  'use strict'
  $(document).ready(function () {
    triNom()

    $('body').on('click', '.btn-filter-regroupement', function () {
      const $btn = $(this)

      const regroupementId = $btn.data('regroupement')
      const $infosWrapper = $('body .infos-selection')

      if ($btn.hasClass('active')) {
        $infosWrapper.hide()
        $btn.removeClass('active')
        $('body .card-regroupement-' + regroupementId).hide('slow')
        if ($('body .btn-filter-regroupement.active').length <= 0) {
          $infosWrapper.html('<div class="form-row"><div class="col-12 alert alert-info"><i class="fa fa-info-circle"></i>&nbsp;<span>Veuillez sélectionner une catégorie pour afficher les activités associées</span></div></div>')
          $infosWrapper.show('slow')
        }

        return
      }

      $('.infos-selection').hide('slow')
      const $card = $('body .card-regroupement-' + regroupementId)
      $card.each(function () {
        if (!document.getElementById('repascheck') || isAllowInFilter($(this)) === true) {
          $(this).show('slow')
        }
      })

      $btn.addClass('active')
    })

    // on active les fonctionnalites de filtre uniquement si les input existent
    // Apparemment on ne fait le test que sur un des champs car si celui-ci existe alors tous les autres existent
    // Il n'existe pas dans le cas où l'option des filtres n'est pas activée
    if (document.getElementById('repascheck')) {
      // On va chercher un des id ci-dessous ça évite de faire X appels pour passer dans la même fonction
      $('#repascheck, #periodeselect, #weekselect, #yearSelect').on('change', function () {
        loopInButton()

        // On va gérer les cas pour afficher ou non le message et le changer en fonction du cas
        if ($('body .btn-filter-regroupement:visible').length === 0) {
          $('body .infos-selection').html('<div class="form-row"><div class="col-12 alert alert-info"><i class="fa fa-info-circle"></i>&nbsp;<span>Aucune activité trouvée pour les filtrés sélectionnées</span></div></div>')
          $('body .infos-selection').show('slow')
        } else {
          if ($('body .btn-filter-regroupement.active').length === 0) {
            $('body .infos-selection').html('<div class="form-row"><div class="col-12 alert alert-info"><i class="fa fa-info-circle"></i>&nbsp;<span>Veuillez sélectionner une catégorie pour afficher les activités associées</span></div></div>')
            $('body .infos-selection').show('slow')
          } else {
            $('body .infos-selection').hide()
          }
        }
      })

      $('#triselect').on('change', function () {
        const val = $(this).val()
        if (val === 'abc') { triNom() } else { triDate() }
      })
    }
  })
})(jQuery)
